import { Card, CardContent, Typography, Box, useTheme, styled, useMediaQuery, Button } from "@mui/material"
import { estimateMathScore, estimateReadingScore } from "./sat-score-estimation"
import { UserContext } from "../UserContext"
import { useContext, useMemo, useState } from "react"
import SettingsIcon from '@mui/icons-material/Settings';
import { EditProfile } from "./EditProfile";
import { Link } from "react-router-dom";
// This would come from your API/database

interface StudentScoreData {
  math: {
    current: number | null;
    starting: number | null;
    target: number | null;
    percentiles: Record<number, number>;
  };
  reading: {
    current: number | null;
    starting: number | null;
    target: number | null;
    percentiles: Record<number, number>;
  };
}

interface ScoreBarProps {
  label: string;
  score: number | null;
  startingScore: number | null;
  targetScore: number | null;
  percentiles: Record<number, number>;
  universities: Array<{
    name: string;
    logo: string;
    score: number;
    type: string;
    isTop: boolean;
  }>;
  type: string;
}

function useStudentScores(): StudentScoreData {
  const { user } = useContext(UserContext);
  // Cache expensive calculations
  const mathScore = useMemo(() => 
    //The top one is more realistic, but the bottom one might be helpful to get users a headstart
    //(estimateMathScore(user?.skills).higher + estimateMathScore(user?.skills).lower) / 2, 
    estimateMathScore(user?.skills).higher,
    [user?.skills]
  );
  const readingWritingScore = useMemo(() => 
    //The top one is more realistic, but the bottom one might be helpful to get users a headstart
    //(estimateReadingScore(user?.skills).higher + estimateReadingScore(user?.skills).lower) / 2, 
    estimateReadingScore(user?.skills).higher,
    [user?.skills]
  );

  // Final data structure with conditional logic inside useMemo
  return useMemo(() => {
    if (!user) {
      return {
        math: {
          current: null,
          starting: null,
          target: null,
          percentiles: {
            25: 520,
            50: 600,
            75: 680,
            90: 740,
          },
        },
        reading: {
          current: null,
          starting: null,
          target: null,
          percentiles: {
            25: 540,
            50: 620,
            75: 700,
            90: 760,
          },
        },
      };
    }

    return {
      math: {
        current: mathScore,
        starting: user?.previousSATScores?.math ? parseInt(user.previousSATScores.math) : null,
        target: user?.targetScores?.math ? parseInt(user.targetScores.math) : null,
        percentiles: {
          25: 520,
          50: 600,
          75: 680,
          90: 740,
        },
      },
      reading: {
        current: readingWritingScore,
        starting: user?.previousSATScores?.readingWriting ? parseInt(user.previousSATScores.readingWriting) : null,
        target: user?.targetScores?.readingWriting ? parseInt(user.targetScores.readingWriting) : null,
        percentiles: {
          25: 540,
          50: 620,
          75: 700,
          90: 760,
        },
      }
    };
  }, [user, mathScore, readingWritingScore]);
}

const MAX_SCORE = 800
const MIN_SCORE = 300

// Expanded university data with a wider range of colleges
const universities = [
  { name: "Harvard", logo: "/images/collegelogos/harvard.webp", score: 770, type: "math", isTop: false },
  { name: "Stanford", logo: "/images/collegelogos/stanford.webp", score: 770, type: "reading", isTop: false },
  { name: "MIT", logo: "/images/collegelogos/mit.svg", score: 790, type: "math", isTop: true },
  { name: "Duke", logo: "/images/collegelogos/duke.webp", score: 750, type: "math", isTop: true },
  { name: "UCLA", logo: "/images/collegelogos/ucla.webp", score: 730, type: "math", isTop: false },
  { name: "UMich", logo: "/images/collegelogos/michigan.jpg", score: 720, type: "reading", isTop: false },
  { name: "UNC", logo: "/images/collegelogos/unc.webp", score: 690, type: "math", isTop: true },
  { name: "UT Austin", logo: "/images/collegelogos/austin.jpg", score: 680, type: "reading", isTop: false },
  { name: "Clemson", logo: "/images/collegelogos/clemson.png", score: 660, type: "reading", isTop: true },
  //{ name: "Florida State", logo: "/images/collegelogos/floridastate.webp", score: 650, type: "reading", isTop: false },
  { name: "Arizona State", logo: "/images/collegelogos/asu.jpg", score: 620, type: "math", isTop: false },
  { name: "LSU", logo: "/images/collegelogos/lsu.jpg", score: 610, type: "reading", isTop: false },
  { name: "U of SC", logo: "/images/collegelogos/uofsc.webp", score: 630, type: "math", isTop: true },
  { name: "Ohio State", logo: "/images/collegelogos/ohio.webp", score: 590, type: "math", isTop: true },
]

const ProgressBar = styled(Box)(({ theme }) => ({
    height: 64,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
    border: `1px solid ${theme.palette.grey[300]}`,
  }))

const ScoreMarker = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  width: 2,
}))

const ScoreLabel = styled(Typography)(({ theme }) => ({
  position: "absolute",
  transform: "translateX(-50%)",
  whiteSpace: "nowrap",
  fontSize: theme.typography.pxToRem(12),
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
}))

function ScoreBar({ label, score, startingScore, targetScore, percentiles, universities, type }: ScoreBarProps) {
  const theme = useTheme()
  const studentScores = useStudentScores();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const currentScore = type === "math" ? studentScores.math.current : studentScores.reading.current;

  const toPosition = (value: number | null) => {
    if (value === null) return 0;
    return ((value - MIN_SCORE) / (MAX_SCORE - MIN_SCORE)) * 100;
  }

  return (
    <Box sx={{ mb: 8 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
        <Typography variant="h6" color="primary.main">{label}</Typography>
      </Box>

      <Box sx={{ position: "relative" }}>
        <ProgressBar>
          <Box
            sx={{
              height: "100%",
              width: `${toPosition(currentScore)}%`,
              backgroundColor: currentScore ? theme.palette.primary.main : theme.palette.background.default,
              transition: theme.transitions.create("width"),
            }}
          />
        </ProgressBar>

        <Box sx={{ position: "absolute", inset: 0, overflow: "visible" }}>
          {startingScore !== null && (
            <ScoreMarker sx={{ left: `${toPosition(startingScore)}%`, backgroundColor: theme.palette.background.default,
              overflow: "visible", width: "auto", zIndex: 2
             }}>
              <ScoreLabel
                sx={{
                  top: "100%",
                  mt: 1,
                  backgroundColor: theme.palette.custom.lightblue,
                  color: theme.palette.background.default,
                }}
              >
                Start: {startingScore}
              </ScoreLabel>
            </ScoreMarker>
          )}

          {targetScore !== null && (
            <ScoreMarker sx={{ left: `${toPosition(targetScore)}%`, backgroundColor: theme.palette.success.main }}>
              <ScoreLabel
                sx={{
                  transform: "translateX(-50%) translateY(+50%)",
                  backgroundColor: theme.palette.secondary.dark,
                  color: theme.palette.background.default,
                  zIndex: 1,
                  padding: "6px 8px",
                  borderRadius: "4px",
                  overflow: "visible",
                }}
              >
                Target: {targetScore}
              </ScoreLabel>
            </ScoreMarker>
          )}

          {Object.entries(percentiles).map(([percentile, value]) => (
            <ScoreMarker
              key={percentile}
              sx={{ left: `${toPosition(value)}%`, backgroundColor: theme.palette.text.disabled }}
            >
              <ScoreLabel sx={{ bottom: "100%", mb: 1, color: theme.palette.text.secondary }}>
                {isMobile ? `${percentile}th` : `${percentile}th percentile`}
              </ScoreLabel>
              <ScoreLabel sx={{ top: "100%", mt: 1, color: theme.palette.text.secondary }}>
                {value}
              </ScoreLabel>
            </ScoreMarker>
          ))}

          {/* University markers - removed on mobile because it's too crowded*/}
          {isMobile ? null : universities
            .filter((uni) => uni.type === type)
            .map((uni) => {
              const position = toPosition(uni.score)
              return (
                <Box
                  key={uni.name}
                  sx={{
                    position: "absolute",
                    left: `${position}%`,
                    top: uni.isTop ? -86 : "120%",
                    transform: "translateX(-50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box component="img" src={uni.logo} alt={uni.name} sx={{ width: 32, height: 32 }} />
                  <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, mt: 0.5 }}>
                    <Typography variant="caption">{uni.name}</Typography>
                    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                      ({uni.score})
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: uni.isTop ? "100%" : "auto",
                      bottom: uni.isTop ? "auto" : "70%",
                      left: "50%",
                      width: 1,
                      height: 64,
                      borderLeft: `1px dashed ${theme.palette.text.disabled}`,
                    }}
                  />
                </Box>
              )
            })}
        </Box>

        {/* Score scale */}
        <Box sx={{ 
        display: "flex", 
        justifyContent: "space-between", 
        mt: 0,
        position: "relative",
        px: 2 
      }}>
        {[300, 400, 500, 800].map((value) => (
          <Typography 
            key={value}
            variant="caption" 
            color="text.secondary"
            sx={{
              position: "absolute",
              left: `${toPosition(value)}%`,
              transform: "translateX(-50%)"
            }}
          >
            {value}
          </Typography>
        ))}

        {/* Current score marker - will be on top */}
        {currentScore !== null && (
        <Typography variant="caption" 
          sx={{position: "absolute", left: `${toPosition(currentScore)}%`, top: "-64px",
            transform: "translateX(-50%) translateY(50%)",
            color: theme.palette.background.default,
            fontWeight: "bold",
            backgroundColor: theme.palette.custom.green,
            padding: "6px 8px",
            borderRadius: "4px",
            zIndex: 1,  // Ensures it's above other labels
            border: `1px solid ${theme.palette.primary.dark}`,
          }}
        >
          {currentScore}
        </Typography>
        )}

      </Box>
      </Box>
    </Box>
  )
}

export default function DetailedAssessmentWithUniversities() {
  const studentScores = useStudentScores();
  const theme = useTheme()
  const { user } = useContext(UserContext);
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Card sx={{ overflow: "visible" }}>
      <CardContent sx={{ px: 3, py: 2 }}>
        <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'flex-start',
            mb: 3
          }}>
            <Box>
              <Typography variant="h4" gutterBottom>
                Student Dashboard
              </Typography>
              {(studentScores.math.starting !== null && studentScores.reading.starting !== null &&
                studentScores.math.current !== null && studentScores.reading.current !== null &&
                studentScores.math.current > studentScores.math.starting && 
                studentScores.reading.current > studentScores.reading.starting) ? (
                <Typography variant="subtitle1" color="text.secondary">
                  {user?.firstName}'s progress: Your Math has increased by {studentScores.math.current - studentScores.math.starting} points and Reading scores have increased by {studentScores.reading.current - studentScores.reading.starting} points since starting! Keep up the good work!
                </Typography>
              ) : (
                <Typography variant="subtitle1" color="text.secondary">Keep up the good work! Start tracking your progress to figure out where you can improve the fastest!</Typography>
              )}
            </Box>
            
            {user ? (
              <Button
                variant="contained"
                startIcon={<SettingsIcon />}
                onClick={() => setIsEditing(true)}  // Add onClick handler
                sx={{borderRadius: 2, whiteSpace: 'nowrap', height: '40px', minWidth: 'fit-content'}}
              >
                Edit Profile
              </Button>
            ) : (
                <Button variant="contained" startIcon={<SettingsIcon />}
                component={Link} to="/create-profile"
                sx={{borderRadius: 2, whiteSpace: 'nowrap', height: '40px', minWidth: 'fit-content'}}
              >
                Create Profile
              </Button>
            )}
          </Box>
        {/*<Typography variant="h4" gutterBottom>
          Student Progress Dashboard
        </Typography>

        {/* ******************************Implement cool AI Summary here later with encouraging tone!*************
        
        {(studentScores.math.starting !== null && studentScores.reading.starting !== null &&
          studentScores.math.current !== null && studentScores.reading.current !== null &&
          studentScores.math.current > studentScores.math.starting && 
          studentScores.reading.current > studentScores.reading.starting) ? (
          <Typography variant="body2" color="text.secondary">
            {user?.firstName}'s progress: Your Math has increased by {studentScores.math.current - studentScores.math.starting} points and Reading scores have increased by {studentScores.reading.current - studentScores.reading.starting} points since starting! Keep up the good work!
          </Typography>
        ) : (
          <Typography variant="body2" color="text.secondary">Create an account or login to see your progress!</Typography>
        )}
        */}

        <ScoreBar
          label="Math"
          score={studentScores.math.current}
          startingScore={studentScores.math.starting}
          targetScore={studentScores.math.target}
          percentiles={studentScores.math.percentiles}
          universities={universities}
          type="math"
        />
        <ScoreBar
          label="Reading & Writing"
          score={studentScores.reading.current}
          startingScore={studentScores.reading.starting}
          targetScore={studentScores.reading.target}
          percentiles={studentScores.reading.percentiles}
          universities={universities}
          type="reading"
        />

        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 4 }}>
          {[
            studentScores.math.starting !== null && studentScores.reading.starting !== null && { color: theme.palette.secondary.main, label: "Starting Score"},
            studentScores.math.current !== null && studentScores.reading.current !== null && { color: theme.palette.custom.green, label: "Your Estimated Score" },
            { color: theme.palette.custom.lightGrey, label: "Percentile Compared to Students Nationwide" },
            studentScores.math.target !== null && studentScores.reading.target !== null && { color: theme.palette.secondary.dark, label: "Goal SAT Scores" },
          ]
          .filter((item): item is { color: string; label: string } => Boolean(item)) // Type guard
          .map((item, index) => (
            <Box key={index} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              
              <Box sx={{ width: 12, height: 12, borderRadius: "50%", backgroundColor: item.color }} />
                <Typography variant="caption" color="text.secondary">{item.label}</Typography>
              </Box>
          ))}
        </Box>

        <EditProfile open={isEditing} onClose={() => setIsEditing(false)} />

      </CardContent>
    </Card>
  )
}

