import React, { useState } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Define the FAQ item type
interface FAQItem {
  question: string;
  answer: string;
}

// Define the component props
interface FAQSectionProps {
  onCTAClick?: () => void;
  ctaButtonText: string;
}

const faqData: FAQItem[] = [
  {
    question: "How is this different from other SAT prep programs?",
    answer: "Unlike traditional courses, our AI-powered tutor personalizes SAT prep based on your teen's strengths and weaknesses by analyzing their history of which question types and difficulties they get right and wrong, then recommending certain skills to practice. Instead of generic lessons, students practice only what will improve their scores fastest—with instant feedback and explanations. Additionally, no other SAT prep program has the voice interaction with an AI tutor!"
  },
  {
    question: "How much does the program cost?",
    answer: "We offer a free trial so students can experience the platform risk-free. After that, our main subscription is $20/month, which includes unlimited practice, personalized AI coaching, and progress tracking. A $30/month plan with additional features will be available soon."
  },
  {
    question: "How does the AI tutor work?",
    answer: "Students choose a skill to practice and have an AI tutor to ask for help with pre-set prompts, or the student can type or verbally speak their questions to practice critical thinking. We have embedded the AI with numerous strategies to teach students in context through hints, suggestions, and explanations."
  },
  {
    question: "Does this program prepare students for the new Digital SAT?",
    answer: "Yes! The SAT format drastically changed in 2024, so we specifically designed this prep for the new Digital SAT format, focusing on new strategies and digital-specific question types."
  },
  {
    question: "Can this really replace an SAT tutor?",
    answer: "For many students, yes! Our AI replicates the best aspects of one-on-one tutoring—real-time feedback, personalized study plans, and skill tracking—at a fraction of the cost. However, students who prefer live instruction may still benefit from working with a tutor alongside our platform."
  },
  {
    question: "What if my teen has never taken an SAT before?",
    answer: "No problem - many of our students have never taken an SAT before! Our system adjusts based on their current level and starts with foundational skills before moving to advanced strategies. They'll get a guided, structured approach to learning, even if they're new to the SAT."
  },/*This is a little repetitive with the one beneath for now. But these FAQs would be cool to update with more specific psychology/education/neuroscience research later! Could be used for SEO stuff too ehehe.
  {
    question: "How much time should my teen spend studying each week?",
    answer: "For best results, we recommend at least 3-4 practice sessions per week (around 30-45 minutes per session). However, even shorter, consistent study sessions can lead to noticeable improvement."
  },*/
  {
    question: "How fast will my teen see results?",
    answer: "Most students see noticeable improvements in as little as 3-4 weeks. However, results vary based on study frequency. Students who practice at least 3-4 times per week for at least 20 minutes per sessiontend to improve significantly within 2 months. Helpful hint: we've found that practicing only once a week isn't as effective because students tend to forget the lessons they learn, so we recommend at least twice per week at the very least."
  },
  /*{This should eventually say, "We generated and validated our own questions...but for now it's the official questions."
    question: "What kind of questions will my teen practice?",
    answer: "We've personally analyzed and categorized the test into 30 skills - no other prep company does this! We provide realistic Digital SAT-style questions across all 30 tested skills, including math, reading, and grammar. Our system prioritizes the most frequently tested concepts to maximize score gains."
  },*/
  {
    question: "Does the AI explain why answers are right or wrong?",
    answer: "Yes! Every question includes detailed explanations, and students can ask the AI for hints, strategies, alternative solutions, or step-by-step breakdowns to ensure deep understanding before, during, and after attempting a question."
  },
  {
    question: "How is progress tracked?",
    answer: 'The "View Profile" page has graphs which show correct answers, skill mastery, and estimated SAT scores over time. Parents and students can see which areas need the most focus and adjust their study plan accordingly.'
  },
  {
    question: "Can I track my child's progress as a parent?",
    answer: "We're working on a parent dashboard that will allow parents to monitor progress, study habits, and score trends. For now, you can log in with the same email/password as your student and see their progress."
  },
  {
    question: "What if my teen gets stuck on a problem?",
    answer: "Our AI provides step-by-step guidance, hints, and alternative explanations. If a student still struggles, they can flag questions for review or revisit them later."
  },
  {
    question: "Does this work for students aiming for top scores (1400+)?",
    answer: "Absolutely! Our adaptive difficulty system ensures that students aiming for 1400+ scores get challenging, high-level questions and strategy-focused explanations."
  },
  {
    question: "Is this suitable for students who struggle with test anxiety?",
    answer: "Yes! Our AI is custom-tailored to be encouraging - we added a fun prompt for just this reason, \"Can tell me a joke and then inspire me to work hard?\" Students can set their own timing, skill types, and difficulties, and the AI interactions offer positive reinforcement and low-stress practice environments that build confidence over time."
  },
  {
    question: "Does the free trial give full access?",
    answer: "Yes! The free trial lets students experience all core features—including unlimited practice, AI coaching, and progress tracking—for one month."
  },
  {
    question: "Can students use this on mobile devices or tablets?",
    answer: "Yes! Our platform is fully optimized for mobile phones, tablets, and desktops, so students can practice anywhere, anytime."
  },
  {
    question: "Does this prep include full-length SAT practice tests?",
    answer: "We focus on targeted skill-building rather than full-length tests. However, students can generate customized practice sections that mimic full tests. A full test mode is in development."
  },
  {
    question: "How do we get started?",
    answer: "Click \"Start Free Trial\" to create an account and begin practicing in under 60 seconds—no credit card required!"
  }
  /*{Decide if I actually want to do this, and if so, what are the conditions?
    question: "What happens if my teen doesn't improve?",
    answer: "We're confident in our system, but if your teen follows the recommended practice schedule and doesn't improve, we offer a money-back guarantee (details apply)."
  },*/
];

const FAQSection: React.FC<FAQSectionProps> = ({onCTAClick, ctaButtonText}) => {
  const theme = useTheme();
  const [expandedPanel, setExpandedPanel] = useState<number | false>(false);

  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ backgroundColor: theme.palette.background.paper, py: 6 }}>
      <Container>
        <Typography variant="h3" component="h2" align="center" sx={{ mb: 4 }}>
          Frequently Asked Questions
        </Typography>
        
        {faqData.map((faq, index) => (
          <Accordion key={index} expanded={expandedPanel === index}
            onChange={handleChange(index)}
            sx={{mb: 0,
              backgroundColor: 'transparent', boxShadow: 'none',
              '&:before': { display: 'none' },
              '& .MuiAccordionSummary-root': {
                borderBottom: `1px solid ${theme.palette.divider}`,
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)'
                }
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="primary" />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              sx={{ py: .5 }}
            >
              <Typography variant="h6" fontWeight="medium">
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ py: 1 }}>
              <Typography variant="body1" sx={{ fontSize: '1.05rem', lineHeight: 1.6 }}>
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
        
        {onCTAClick && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Button 
              variant="contained" 
              fullWidth
              color="primary" 
              size="large"
              onClick={onCTAClick}
              sx={{ px: 4, py: 1.5, fontSize: '1.2rem', fontWeight: 'bold' }}
            >
              {ctaButtonText}
            </Button>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default FAQSection;