import React from 'react';

// Types for Meta Pixel events
interface PixelEventParams {
    content_name?: string;
    content_category?: string;
    content_type?: string;
    currency?: string;
    value?: number;
    event_time?: number;
    [key: string]: any;
  }
  
  // Declare the fbq function globally
  declare global {
    interface Window {
      fbq: (
        type: string,
        eventName: string,
        params?: PixelEventParams
      ) => void;
    }
  }
  
  // Standard Meta Pixel events
  export const PixelEvents = {
    NAVIGATION: 'Navigation',
    MAIN_NAV_CLICK: 'MainNavClick',
    INITIATE_STRIPE_CHECKOUT_MONTHLY: 'InitiateStripeCheckout',
    INITIATE_STRIPE_CHECKOUT_LIFETIME: 'InitiateStripeCheckoutLifetime',
    VIEW_CONTENT: 'ViewContent',
    PURCHASE: 'Purchase',
    START_TRIAL: 'StartTrial',
    INITIATE_CREATE_PROFILE: 'InitiateCreateProfile',
    COMPLETE_CREATE_PROFILE: 'CompleteCreateProfile',
  } as const;
  
  // Utility function to track events
    export const trackPixelEvent = (eventName: string, parameters?: PixelEventParams) => {
        // TODO: Add check to validate correct eventName with "keyof typeof PixelEvents" instead of string
        const eventParams = {...parameters, event_time: Date.now() / 1000}; //Converts current time to seconds
        
        if (process.env.NODE_ENV === 'development') {
            console.log(`[Meta Pixel Event] ${eventName}`, parameters);
        }
    
        if (window.fbq) {
            if (Object.values(PixelEvents).includes(eventName as any)) {
                window.fbq('track', eventName, parameters);
            } else {
                window.fbq('trackCustom', eventName, parameters);
            }
        }
    };

    export const trackHomePageCTAButton = (
        fromPage: string,
        toPage: string,
        buttonName: string
    ) => {
        trackPixelEvent(PixelEvents.NAVIGATION, {
            content_name: buttonName,
            from_page: fromPage,
            to_page: toPage,
            content_category: 'Navigation',
            content_type: 'button',
            event_time: Date.now() / 1000,
        });
    }

    export const trackMainNavClick = (fromPage: string, buttonName: string) => {
        trackPixelEvent(PixelEvents.MAIN_NAV_CLICK, {
            content_name: buttonName,
            from_page: fromPage,
            content_category: 'Main Navigation',
            content_type: 'button',
            event_time: Date.now() / 1000,
        });
    }

