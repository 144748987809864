import { useContext } from "react"
import { UserContext, categories, Skills } from "../UserContext"

interface ScoreRange {
    threshold: number;
    lower: number;
    higher: number;
}

const readingScoreTable: ScoreRange[] = [
    { threshold: 0.00, lower: 200, higher: 200 },
    { threshold: 0.02, lower: 200, higher: 200 },
    { threshold: 0.03, lower: 200, higher: 200 },
    { threshold: 0.05, lower: 200, higher: 210 },
    { threshold: 0.06, lower: 200, higher: 220 },
    { threshold: 0.08, lower: 200, higher: 240 },
    { threshold: 0.09, lower: 200, higher: 250 },
    { threshold: 0.11, lower: 200, higher: 260 },
    { threshold: 0.12, lower: 200, higher: 270 },
    { threshold: 0.14, lower: 200, higher: 280 },
    { threshold: 0.15, lower: 200, higher: 300 },
    { threshold: 0.17, lower: 200, higher: 310 },
    { threshold: 0.18, lower: 200, higher: 320 },
    { threshold: 0.20, lower: 200, higher: 330 },
    { threshold: 0.21, lower: 200, higher: 350 },
    { threshold: 0.23, lower: 210, higher: 360 },
    { threshold: 0.24, lower: 240, higher: 390 },
    { threshold: 0.26, lower: 260, higher: 410 },
    { threshold: 0.27, lower: 270, higher: 420 },
    { threshold: 0.29, lower: 280, higher: 430 },
    { threshold: 0.30, lower: 290, higher: 440 },
    { threshold: 0.32, lower: 290, higher: 440 },
    { threshold: 0.33, lower: 300, higher: 450 },
    { threshold: 0.35, lower: 310, higher: 460 },
    { threshold: 0.36, lower: 320, higher: 470 },
    { threshold: 0.38, lower: 330, higher: 480 },
    { threshold: 0.39, lower: 340, higher: 490 },
    { threshold: 0.41, lower: 340, higher: 490 },
    { threshold: 0.42, lower: 350, higher: 500 },
    { threshold: 0.44, lower: 360, higher: 510 },
    { threshold: 0.45, lower: 370, higher: 520 },
    { threshold: 0.47, lower: 380, higher: 530 },
    { threshold: 0.48, lower: 380, higher: 530 },
    { threshold: 0.50, lower: 390, higher: 540 },
    { threshold: 0.52, lower: 400, higher: 550 },
    { threshold: 0.53, lower: 410, higher: 560 },
    { threshold: 0.55, lower: 410, higher: 560 },
    { threshold: 0.56, lower: 420, higher: 570 },
    { threshold: 0.58, lower: 430, higher: 580 },
    { threshold: 0.59, lower: 440, higher: 590 },
    { threshold: 0.61, lower: 450, higher: 600 },
    { threshold: 0.62, lower: 460, higher: 610 },
    { threshold: 0.64, lower: 460, higher: 610 },
    { threshold: 0.65, lower: 470, higher: 620 },
    { threshold: 0.67, lower: 480, higher: 630 },
    { threshold: 0.68, lower: 490, higher: 640 },
    { threshold: 0.70, lower: 500, higher: 650 },
    { threshold: 0.71, lower: 510, higher: 660 },
    { threshold: 0.73, lower: 510, higher: 660 },
    { threshold: 0.74, lower: 520, higher: 670 },
    { threshold: 0.76, lower: 530, higher: 680 },
    { threshold: 0.77, lower: 550, higher: 700 },
    { threshold: 0.79, lower: 560, higher: 710 },
    { threshold: 0.80, lower: 560, higher: 710 },
    { threshold: 0.82, lower: 570, higher: 720 },
    { threshold: 0.83, lower: 580, higher: 730 },
    { threshold: 0.85, lower: 600, higher: 750 },
    { threshold: 0.86, lower: 610, higher: 760 },
    { threshold: 0.88, lower: 630, higher: 770 },
    { threshold: 0.89, lower: 650, higher: 780 },
    { threshold: 0.91, lower: 670, higher: 790 },
    { threshold: 0.92, lower: 690, higher: 800 },
    { threshold: 0.94, lower: 710, higher: 800 },
    { threshold: 0.95, lower: 730, higher: 800 },
    { threshold: 0.97, lower: 750, higher: 800 },
    { threshold: 0.98, lower: 770, higher: 800 },
    { threshold: 1.00, lower: 800, higher: 800 }
];


const mathScoreTable = [
    { threshold: 0.00, lower: 200, higher: 200 },
    { threshold: 0.02, lower: 200, higher: 200 },
    { threshold: 0.04, lower: 200, higher: 210 },
    { threshold: 0.06, lower: 200, higher: 220 },
    { threshold: 0.07, lower: 200, higher: 240 },
    { threshold: 0.09, lower: 200, higher: 260 },
    { threshold: 0.11, lower: 200, higher: 270 },
    { threshold: 0.13, lower: 200, higher: 280 },
    { threshold: 0.15, lower: 200, higher: 290 },
    { threshold: 0.17, lower: 200, higher: 300 },
    { threshold: 0.19, lower: 200, higher: 330 },
    { threshold: 0.20, lower: 210, higher: 360 },
    { threshold: 0.22, lower: 220, higher: 370 },
    { threshold: 0.24, lower: 230, higher: 380 },
    { threshold: 0.26, lower: 240, higher: 390 },
    { threshold: 0.28, lower: 250, higher: 400 },
    { threshold: 0.30, lower: 260, higher: 410 },
    { threshold: 0.31, lower: 260, higher: 410 },
    { threshold: 0.33, lower: 270, higher: 420 },
    { threshold: 0.35, lower: 280, higher: 430 },
    { threshold: 0.37, lower: 290, higher: 440 },
    { threshold: 0.39, lower: 300, higher: 450 },
    { threshold: 0.41, lower: 300, higher: 450 },
    { threshold: 0.43, lower: 310, higher: 460 },
    { threshold: 0.44, lower: 320, higher: 470 },
    { threshold: 0.46, lower: 330, higher: 480 },
    { threshold: 0.48, lower: 350, higher: 500 },
    { threshold: 0.50, lower: 360, higher: 510 },
    { threshold: 0.52, lower: 370, higher: 520 },
    { threshold: 0.54, lower: 380, higher: 530 },
    { threshold: 0.56, lower: 390, higher: 540 },
    { threshold: 0.57, lower: 400, higher: 550 },
    { threshold: 0.59, lower: 420, higher: 570 },
    { threshold: 0.61, lower: 430, higher: 580 },
    { threshold: 0.63, lower: 440, higher: 590 },
    { threshold: 0.65, lower: 450, higher: 600 },
    { threshold: 0.67, lower: 470, higher: 620 },
    { threshold: 0.69, lower: 480, higher: 630 },
    { threshold: 0.70, lower: 490, higher: 640 },
    { threshold: 0.72, lower: 500, higher: 650 },
    { threshold: 0.74, lower: 510, higher: 660 },
    { threshold: 0.76, lower: 520, higher: 670 },
    { threshold: 0.78, lower: 540, higher: 690 },
    { threshold: 0.80, lower: 550, higher: 700 },
    { threshold: 0.81, lower: 570, higher: 710 },
    { threshold: 0.83, lower: 590, higher: 720 },
    { threshold: 0.85, lower: 610, higher: 730 },
    { threshold: 0.87, lower: 630, higher: 740 },
    { threshold: 0.89, lower: 650, higher: 740 },
    { threshold: 0.91, lower: 680, higher: 750 },
    { threshold: 0.93, lower: 700, higher: 760 },
    { threshold: 0.94, lower: 730, higher: 770 },
    { threshold: 0.96, lower: 750, higher: 790 },
    { threshold: 0.98, lower: 770, higher: 800 },
    { threshold: 1.00, lower: 800, higher: 800 }
];

// Helper function to find score range
const findScore = (percentage: number, table: ScoreRange[]): ScoreRange => {
    const nextRow = table.find(row => row.threshold > percentage);
    if (!nextRow) return { threshold: 1, lower: 800, higher: 800 };
    
    const index = table.indexOf(nextRow);
    return table[Math.max(0, index - 1)];
  };

const formatSkillName = (skill: string): string => {
return skill.toLowerCase().replace(/ /g, '_');
};

// Calculate average percentage for each category
const calculateCategoryAverage = (categorySkills: readonly string[], skills: Partial<Skills>): number => {
    const skillPercentages = [...categorySkills].map(skillName => {
        const formattedSkillName = formatSkillName(skillName);
        const skillData = skills[formattedSkillName];
        if (!skillData) return 0.3; // Fallback for missing skills

        const attempted = skillData.currentTestQuestionsAttempted;
        const correct = skillData.currentTestQuestionsCorrect;

        // Use 0.3 as fallback if no attempts
        return attempted === 0 ? 0.3 : correct / attempted;
    });

    // Calculate average percentage for this category
    return skillPercentages.reduce((sum, pct) => sum + pct, 0) / skillPercentages.length;
};

export const estimateReadingScore = (skills?: Partial<Skills>): { lower: number, higher: number} => { 
    if (!skills) return { lower: 200, higher: 200};

    const readingWritingAvg = calculateCategoryAverage(
        categories["Reading and Writing"],
        skills
      );
    
    return findScore(readingWritingAvg, readingScoreTable);
};

export const estimateMathScore = (skills?: Partial<Skills>): { lower: number; higher: number } => {
    if (!skills) return { lower: 200, higher: 200 };
  
    const mathAvg = calculateCategoryAverage(
      categories["Math"],
      skills
    );
  
    return findScore(mathAvg, mathScoreTable);
  };

  export const estimateSATScore = (skills?: Partial<Skills>): number => {
    const mathScores = estimateMathScore(skills);
    const readingScores = estimateReadingScore(skills);
  
    // Use the average of lower and higher bounds for both sections
    const mathMidpoint = Math.round((mathScores.lower + mathScores.higher) / 2);
    const readingMidpoint = Math.round((readingScores.lower + readingScores.higher) / 2);
  
    return mathMidpoint + readingMidpoint;
  };

  // Component for displaying scores
export const SATScoreDisplay: React.FC = () => {
    const mathScores = estimateMathScore();
    const readingScores = estimateReadingScore();
    const totalScore = estimateSATScore();
    
    //NOTE: This return isn't actually displayed anywhere.
    return (
    <div>
        <div>Math: {`${mathScores.lower}-${mathScores.higher}`}</div>
        <div>Reading: {`${readingScores.lower}-${readingScores.higher}`}</div>
        <div>Total: {totalScore}</div>
      </div>
    );
  };