import React, { useState, useContext, useMemo } from "react";
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { UserContext, SkillData, categories, CategoryName, skillFrequency, SkillFrequency } from "../UserContext";

const difficulties = ['Easy', 'Medium', 'Hard'] as const;

const HeatmapCell = styled(TableCell)<{performance: number}>(({ theme, performance }: {theme: any, performance: number}) => ({
  padding: theme.spacing(1),
  '& > div': {
    width: '100%',
    height: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(12),
    backgroundColor: (() => {
      if (performance >= 80) return '#22c55e'  // bg-green-500
      if (performance >= 60) return '#86efac'  // bg-green-300
      if (performance >= 40) return '#fde047'  // bg-yellow-300
      if (performance >= 20) return '#fca5a5'  // bg-red-300
      if (performance > 0) return '#ef4444'    // bg-red-500
      return theme.palette.grey[500]
    })(),
  },
}));

export function SkillChartByDifficultyAndFrequency() {
  const { user } = useContext(UserContext);
  const [selectedCategory, setSelectedCategory] = useState<CategoryName>("Reading and Writing");
  const theme = useTheme();


  // Calculate performance for a given skill and difficulty
  const calculatePerformance = (skill: string, difficulty: 'Easy' | 'Medium' | 'Hard'): number => {
    if (!user?.skills) return 0; //not really going to happen, just screwing with TypeScript...I wrote this code at 3 am, feel like it's hella inconsistent. RIP my data cleanliness...
    const skillKey = skill.toLowerCase().replace(/ /g, "_");
    const skillData = user?.skills[skillKey] || null; //Will trigger a return of 0 on the next line if skillKey is not found
    if (!skillData) return 0; //Guaranteed for some skills because most users won't have already answered ALL skills yet
    //const attemptedKey = `currentTest${difficulty}QuestionsAttempted` as keyof SkillData;
    //const correctKey = `currentTest${difficulty}QuestionsCorrect` as keyof SkillData;

    const attempted = skillData[`currentTest${difficulty}QuestionsAttempted`] || 0;
    const correct = skillData[`currentTest${difficulty}QuestionsCorrect`] || 0;
    return attempted === 0 ? 0 : Math.round((correct / attempted) * 100);
  };

  //This sorts the skills in the table by frequency on the test and is memoized so it only happens once since frequencies don't change.
  const sortedSkills = useMemo(() => {
    return [...categories[selectedCategory]].sort((a, b) => {
      const freqA = skillFrequency[a as keyof SkillFrequency] || 0;
      const freqB = skillFrequency[b as keyof SkillFrequency] || 0;
      return freqB - freqA; // Sort in descending order
    });
  }, [selectedCategory]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="h5" color="primary.main">Skill Summary</Typography>
          <Typography variant="body2" color="text.secondary">Performance across skills and difficulties</Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 4 }}>
          {Object.keys(categories).map((category) => (
            <Button
              key={category}
              onClick={() => setSelectedCategory(category as CategoryName)}
              variant={selectedCategory === category ? 'contained' : 'outlined'}
              color="primary"
              sx={{ maxHeight: '50px'}}
            >
              {category}
            </Button>
          ))}
        </Box>
      </Box>

        {/* Heatmap (Chart) Table */}
        <Box sx={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Skill</TableCell>
                <TableCell>Frequency on Test</TableCell>
                {difficulties.map(difficulty => (
                  <TableCell key={difficulty}>{difficulty} % Correct</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedSkills.map(skill => (
                <TableRow key={skill}>
                  <TableCell>{skill}</TableCell>
                  <TableCell>
                    {((skillFrequency[skill as keyof SkillFrequency] || 0) * 100).toFixed(1)}%
                  </TableCell>
                  {difficulties.map((difficulty) => {
                    const performance = calculatePerformance(skill, difficulty as 'Easy' | 'Medium' | 'Hard');
                    return (
                      <HeatmapCell key={`${skill}-${difficulty}`} performance={performance}>
                        <div>{performance}%</div>
                      </HeatmapCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
    </Box>
  )
}


/*Functions from SkillButtonsAndQuestionGenerator.tsx that I know work for accessing user values.
  const getSkillData = (skillKey: string) => {
    // Return user data if it exists, otherwise return session data
    return user?.skills[skillKey] || {
      currentTestEasyQuestionsAttempted: 0,
      currentTestEasyQuestionsCorrect: 0,
      currentTestMediumQuestionsAttempted: 0,
      currentTestMediumQuestionsCorrect: 0,
      currentTestHardQuestionsAttempted: 0,
      currentTestHardQuestionsCorrect: 0
    };
  };

  // Function to calculate overall skill percentage correct from user data
  const calculateSpecificDifficultyPercentage = (skill: string, difficulty: 'Easy' | 'Medium' | 'Hard') => {
    const skillData = getSkillData(skillNameToKey(skill));
    console.log(`skillData is ${skillData}. That means ${skillData[`currentTest${difficulty}QuestionsAttempted`]} attempted and ${skillData[`currentTest${difficulty}QuestionsCorrect`]} correct.`);
    const attempted = skillData[`currentTest${difficulty}QuestionsAttempted`] || 0;
    const correct = skillData[`currentTest${difficulty}QuestionsCorrect`] || 0;
    console.log(`New ${skill} ${difficulty} percentage is ${Math.round((correct / attempted) * 100)}`);
    
    return attempted === 0 ? 0 : Math.round((correct / attempted) * 100);
  };*/

