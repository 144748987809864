import React, { createContext, useState, useEffect, ReactNode } from 'react';
import ReactMarkdown from 'react-markdown'; // Import ReactMarkdown
import remarkGfm from 'remark-gfm'; // Extends Markdown to render tables. Might remove this later, but check if this ruins the other remarks.
import remarkMath from 'remark-math'; // For parsing LaTeX math expressions
import rehypeKatex from 'rehype-katex'; // For rendering math with KaTeX
import 'katex/dist/katex.min.css'; // Import Katex CSS for styling
import rehypeRaw from 'rehype-raw'; // Import rehypeRaw to enable raw HTML for the tables
import { Pluggable } from 'unified'; // Import Pluggable type to assert that rehypeRaw is valid config for Markdown to render HTML
import { Typography } from '@mui/material';

//This Component stores generatedSATQuestion, which comes from SkillButtonsAndQuestionGenerator.tsx
//The purpose is to pass that question to the ChatComponent or other Components on the same page.

type InteractionStage = 'notStarted' | 'questionGenerated' | 'answered';

type PromptContextType = {
  questionText: string;
  choices: string[];
  correctAnswer: string;
  explanationText: string;
  selectedSubject: string;
  selectedSkill: string;
  selectedDifficulty: 'Easy' | 'Medium' | 'Hard';
  interactionStage: 'notStarted' | 'questionGenerated' | 'answered'; // Used to render quickPrompts based on how far into a question the user is
  setInteractionStage: (stage: InteractionStage) => void;
  setQuestionText: (text: string) => void;
  setChoices: (choices: string[]) => void;
  setCorrectAnswer: (answer: string) => void;
  setExplanationText: (text: string) => void;
  setSelectedSubject: (subject: string) => void;
  setSelectedSkill: (skill: string) => void;
  setSelectedDifficulty: (difficulty: 'Easy' | 'Medium' | 'Hard') => void;
  clearChat: () => void; // Method to clear chat history
  renderLatexOrText: (content: string) => JSX.Element;
  chatCleared: boolean; // A boolean flag to trigger chat clearing
  questionData: any[] | null; //Array to store SAT questions loaded from 'all-sat-tests-final.json'
};

export const PromptContext = createContext<PromptContextType | undefined>(undefined);

export const PromptProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [questionText, setQuestionText] = useState<string>('');
  const [choices, setChoices] = useState<string[]>([]); //default is empty array - in case of no choices on Math questions
  const [correctAnswer, setCorrectAnswer] = useState<string>(''); //how might this handle multiple answers (i.e. "4.5" or "9/2")
  const [explanationText, setExplanationText] = useState<string>('');
  const [selectedSubject, setSelectedSubject] = useState<string>('');
  const [selectedSkill, setSelectedSkill] = useState<string>('Function of Sentence');
  const [selectedDifficulty, setSelectedDifficulty] = useState<'Easy' | 'Medium' | 'Hard'>('Easy');
  const [chatCleared, setChatCleared] = useState<boolean>(false);
  const [questionData, setQuestionData] = useState<any[] | null>(null); 
  const [interactionStage, setInteractionStage] = useState<InteractionStage>('notStarted');

  //NEW on 11/11/2024: useEffect to load question data when the component mounts
  useEffect(() => {
    const loadQuestions = async () => {
      try {
        //console.log("Loading questions from all-sat-tests-final.json");
        const response = await fetch('/satquestions/all-sat-tests-final.json'); 
        if (!response.ok) {
          throw new Error(`Failed to load question data. Status: ${response.status}`);
        }
        const data = await response.json();
        setQuestionData(data.questions); //NEW: Set the loaded questions into state
      } catch (error) {
        console.error('Error loading question data:', error);
      }
    };
    loadQuestions(); //This is where the function is actually called
  }, []);

  const clearChat = () => {
    //console.log("setting ChatCleared to true in clearChat() in PromptContext");
    setChatCleared(true);
    setTimeout(() => setChatCleared(false), 250); // Reset the flag .25 seconds (250 milliseconds) after triggering
  };

  //Used to display LaTeX or regular content in the question, answer choices, and explanation.
  const renderLatexOrText = (content: string, textSize: 'normal' | 'small' = 'normal') => {
    
    // Replace "\n" with proper line breaks, then switches "_text_" but not "_____" to a span class="underline" to underline w/o HTML 
    const formattedContent = content
    .replace(/(?<!_)_(?!_)(.*?)(?<!_)_(?!_)/g, (match, p1) => {
      console.log('Found match:', match);
      console.log('Replacing with:', `||${p1}||`);
      return `||${p1}||`;
    });
    const rehypePluginsList: Pluggable[] = [rehypeKatex]; // Default plugins to render Math expressions in KaTeX / LaTeX
    // Check if the content contains any HTML table elements. The "/" makes it Regex expression, so no quotation marks necessary.
    
    // Conditionally add rehypeRaw if the content contains table elements
    const containsTable = /<table>|<tr>|<td>|<th>/.test(formattedContent);
    if (containsTable) {
      rehypePluginsList.push(rehypeRaw);
    }

  return (
    <div className={`rendered-question ${textSize === 'small' ? 'small-text' : ''}`}> {/* Center block elements style={{ textAlign: 'center' }}*/}
        <ReactMarkdown
          remarkPlugins={[remarkGfm, remarkMath]} // Enable GFM and LaTeX parsing
          rehypePlugins={rehypePluginsList} // Enable raw HTML rendering conditionally // Use KaTeX for rendering LaTeX math
          components={{
            p: ({ children, ...props }) => {
              //console.log('Paragraph received:', children);
              // Process children to handle underlining
              const processedChildren = React.Children.map(children, child => {
                if (typeof child === 'string') {
                  //console.log('Processing string in paragraph:', child);
                  const parts = child.split('||');
                  return parts.map((part, i) => {
                    //console.log(`Processing part ${i}:`, part);
                    return i % 2 === 0 ? 
                      part : 
                      <span key={i} className="underline">{part}</span>
                  });
                }
                return child;
              });
              return <p style={{fontSize: '1rem'}}
                {...props}>{processedChildren}</p>;
            },
            li: ({ node, children, ...props }) => (
              <li style={{ fontSize: textSize === 'small' ? '0.875rem' : '1rem', lineHeight: textSize === 'small' ? '1.43' : '1.5' }}
               {...props}>{children}</li>
            ),
            table: ({ node, ...props }) => <table className="sat-question-table" {...props} />,
            tr: ({ node, ...props }) => <tr {...props} />,
            td: ({ node, ...props }) => <td {...props} />,
            th: ({ node, ...props }) => <th {...props} />
          }}
        >
          {formattedContent}
        </ReactMarkdown>
    </div>
  );
};
 
  return (
    <PromptContext.Provider value={{ questionText, choices, correctAnswer, explanationText,  selectedSkill, questionData, selectedSubject, 
      selectedDifficulty, setQuestionText, setChoices, setCorrectAnswer, setExplanationText, setSelectedSkill, clearChat, chatCleared, 
      renderLatexOrText, setSelectedSubject, setSelectedDifficulty, interactionStage, setInteractionStage
    }}
      >
      {children}
    </PromptContext.Provider>
  );
};


/* PREVIOUS:
type PromptContextType = {
  generatedSATQuestion: string;
  //setGeneratedSATQuestion: React.Dispatch<React.SetStateAction<string>>;// Previously was (generatedSATQuestion: string) => void;
  setGeneratedSATQuestion: (question: string) => void;

  /* CLEAR CHAT: not functional but add back in later 
  clearChat: () => void; // Method to clear chat history
  chatCleared: boolean; // A boolean flag to trigger chat clearing
};
*/

/* Define the LineBreak component  - worked on this at 5:48 pm on 11/6!
  const LineBreak = () => <br />;

  // Split the content by \n and map it to include LineBreak components
  const contentWithLineBreaks = formattedContent.split('\n').map((part, index) => (
    <React.Fragment key={index}>
      {index > 0 && <LineBreak />}
      {part}
    </React.Fragment>
  ));   */

  //returns all these values to any Component that needs these:
//Removed this at 1:09 am on 9/18: selectedSubject, selectedDifficulty, setSelectedSubject, setSelectedDifficulty,