import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from 'lucide-react'; //AlertCircle, Trophy, Calendar, CheckCircle2, BookOpen,
//import {  CardTitle } from "./ui/card"; //CardHeader, Card, CardContent - these were originally in /ui/card, but also in mui?
//import { Alert, AlertDescription } from "./ui/alert";
import { UserContext, type UserData } from './UserContext';
import { getAuth, setPersistence, browserLocalPersistence, createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import { auth, db } from '../firebase'; // importsinstance of db from firebase.ts. 
import { Box, Stack, Typography, Button, TextField, Card, CardContent, useTheme } from '@mui/material';
import { trackPixelEvent, PixelEvents } from '../utils/meta/metaPixel';

export type FormData = {
    email: string;
    password: string;
    firstName: string;
    
    hasTakenSAT: boolean;
    hasTakenACT: boolean;
    previousSATScores: { math: string; readingWriting: string };
    previousACTScores: { math: string; reading: string; writing: string; science: string };
    hasUpcomingTest: boolean; 
    testDate: string; 
    targetScores: { math: string; readingWriting: string };
    colleges: string;
    intendedMajor: string;
    motivation: string;
    strengths: string;
    areasToImprove: string;
    feedbackStyle: string;
    //areasForImprovement: string[];
    personalInterests: string;
  };

interface FormErrors {
  satMath?: string;
  satReading?: string;
  testDate?: string;
  targetMath?: string;
  targetReading?: string;
  colleges?: string;
  motivation?: string;
  feedbackStyle?: string;
  areasForImprovement?: string;
  personalInterests?: string;
  email?: string;
  password?: string;
  firstName?: string;
  submit?: string;
  [key: string]: string | undefined;
}

/*
const improvementAreas = [
  { id: 'math', label: 'Math', icon: '📊' },
  { id: 'reading', label: 'Reading', icon: '📚' },
  { id: 'writing', label: 'Writing Skills', icon: '✍️' },
  { id: 'timeManagement', label: 'Time Management', icon: '⏰' },
  { id: 'testAnxiety', label: 'Test Anxiety', icon: '😰' }
] as const;

type ImprovementArea = typeof improvementAreas[number]['id'];*/

// Update component definition with type
const CreateProfile: React.FC = () => {
  const { updateUserData } = useContext(UserContext);
  const navigate = useNavigate();
  const theme = useTheme();

  const [formData, setFormData] = useState<FormData>({
    email: '',
    password: '',
    firstName: '',
    hasTakenSAT: false,
    hasTakenACT: false,
    previousSATScores: { math: '', readingWriting: '' },
    previousACTScores: { math: '', reading: '', writing: '', science: '' },
    hasUpcomingTest: false,
    testDate: '',
    targetScores: { math: '', readingWriting: '' },
    colleges: '',
    intendedMajor: '',
    strengths: '',
    areasToImprove: '',
    motivation: '',
    feedbackStyle: '',
    personalInterests: ''//This should be removed as it's not used
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const [step, setStep] = useState(1);
  const totalSteps = 4; //originally 5; removed step 4, but put it back in if you want Feedback Styles
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateStep = (currentStep: number): boolean => {
    const newErrors: FormErrors = {};

    switch (currentStep) {
      case 1:
        if (formData.previousSATScores.math && !validateSATScore(formData.previousSATScores.math)) {
          newErrors.satMath = 'SAT Math score must be between 200 and 800';
        }
        if (formData.previousSATScores.readingWriting && !validateSATScore(formData.previousSATScores.readingWriting)) {
          newErrors.satReading = 'SAT Reading score must be between 200 and 800';
        }
        
        break;
      case 2:
        if (formData.targetScores.math &&!validateSATScore(formData.targetScores.math)) {
          newErrors.targetMath = 'Target Math score must be between 200 and 800';
        }
        if (formData.targetScores.readingWriting &&!validateSATScore(formData.targetScores.readingWriting)) {
          newErrors.targetReading = 'Target Reading score must be between 200 and 800';
        }
        break;
      case 3:
        //Add any other validations here, but I don't think they're necessary
        //if (!formData.motivation.trim()) newErrors.motivation = 'Please share what motivates you';
        break;
      case 4:
        //if (!formData.feedbackStyle) newErrors.feedbackStyle = 'Please select your preferred feedback style';
        break;
      case 5:
        // Email validation
      if (!formData.email.trim()) {
        newErrors.email = 'Email is required';
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
        newErrors.email = 'Please enter a valid email address';
      }
      // Password validation
      if (!formData.password) {
        newErrors.password = 'Password is required';
      } else if (formData.password.length < 6) {
        newErrors.password = 'Password must be at least 6 characters';
      }
      // First name validation
      if (!formData.firstName.trim()) {
        newErrors.firstName = 'First name is required';
      }
      break;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateSATScore = (score: string): boolean => {
    const numScore = parseInt(score);
    return !isNaN(numScore) && numScore >= 200 && numScore <= 800;
  };

  /*const validateACTScore = (score: string): boolean => {
    const numScore = parseInt(score);
    return !isNaN(numScore) && numScore >= 1 && numScore <= 36;
  };*/

  const renderStep1 = () => (
    <Stack spacing={3} sx={{ width: '100%', maxWidth: 800, marginTop: '0 !important', padding: 2 }}>
    <Card sx={{ width: '100%' }}>
      <CardContent>
        <Typography variant="body1" sx={{marginBottom: 2}}>
          Have you taken the SAT before? If so, enter your scores below, otherwise press "Continue".
        </Typography>

        <Stack spacing={2}>
          <TextField label="SAT Math" type="number" value={formData.previousSATScores.math}
            onChange={(e) => setFormData(prev => ({
              ...prev, previousSATScores: { ...prev.previousSATScores, math: e.target.value }
            }))}
            fullWidth error={!!errors.satMath} helperText={errors.satMath}
          />
          <TextField label="SAT Reading and Writing" type="number" value={formData.previousSATScores.readingWriting}
            onChange={(e) => setFormData(prev => ({
              ...prev, previousSATScores: { ...prev.previousSATScores, readingWriting: e.target.value }
            }))}
            fullWidth error={!!errors.satReading} helperText={errors.satReading}
          />
        </Stack>
        

        {/*Button which shows the ACT section below:
        <Button variant={formData.hasTakenACT ? 'contained' : 'outlined'}
            onClick={() => setFormData(prev => ({ ...prev, hasTakenACT: !prev.hasTakenACT }))}
          >
            ACT
        </Button>
        
        {formData.hasTakenACT && (
          <Stack spacing={2} sx={{ marginBottom: 2 }}>
            <TextField label="ACT Math" type="number" value={formData.previousACTScores.math}
              onChange={(e) => setFormData(prev => ({
                ...prev, previousACTScores: { ...prev.previousACTScores, math: e.target.value }
              }))}
              fullWidth error={!!errors.actMath} helperText={errors.actMath}
            />
            <TextField label="ACT Reading" type="number" value={formData.previousACTScores.reading}
              onChange={(e) => setFormData(prev => ({
                ...prev, previousACTScores: { ...prev.previousACTScores, reading: e.target.value }
              }))}
              fullWidth error={!!errors.actReading} helperText={errors.actReading}
            />
            <TextField label="ACT Writing" type="number" value={formData.previousACTScores.writing}
              onChange={(e) => setFormData(prev => ({
                ...prev, previousACTScores: { ...prev.previousACTScores, writing: e.target.value }
              }))}
              fullWidth error={!!errors.actWriting} helperText={errors.actWriting}
            />
            <TextField label="ACT Science" type="number" value={formData.previousACTScores.science}
              onChange={(e) => setFormData(prev => ({
                ...prev, previousACTScores: { ...prev.previousACTScores, science: e.target.value }
              }))}
              fullWidth error={!!errors.actScience} helperText={errors.actScience}
            />
          </Stack>
        )}*/}

        {/*<Typography variant="body1" gutterBottom>
          Have you scheduled an upcoming test? If so, when is it?
        </Typography>
        
        <Button
          variant={formData.hasUpcomingTest ? 'contained' : 'outlined'}
          onClick={() => setFormData(prev => ({ ...prev, hasUpcomingTest: !prev.hasUpcomingTest }))}
          sx={{ marginBottom: 2 }}
        >
          I have an upcoming test
        </Button>

        {formData.hasUpcomingTest && (
          <TextField
            type="date"
            value={formData.testDate}
            onChange={(e) => setFormData(prev => ({
              ...prev, testDate: e.target.value
            }))}
            fullWidth
            error={!!errors.testDate}
            helperText={errors.testDate}
          />
        )}*/}
      </CardContent>
    </Card>
  </Stack>
  );

  const renderStep2 = () => (
    <Stack spacing={3} sx={{ marginTop: '0 !important', width: '100%', maxWidth: 800, padding: 2 }}>
    <Card sx={{ width: '100%' }}>
      <CardContent>
        <Typography variant="body1" gutterBottom>
          What are the scores you're aiming for?
        </Typography>
        
        <Stack spacing={2} sx={{ marginY: 2 }}>
          <TextField label="Math" type="number" placeholder="200-800"
            value={formData.targetScores.math}
            onChange={(e) => setFormData(prev => ({
              ...prev,
              targetScores: { ...prev.targetScores, math: e.target.value }
            }))}
            fullWidth error={!!errors.targetMath} helperText={errors.targetMath}
          />
          
          <TextField label="Reading and Writing" type="number" placeholder="200-800"
            value={formData.targetScores.readingWriting}
            onChange={(e) => setFormData(prev => ({
              ...prev,
              targetScores: { ...prev.targetScores, readingWriting: e.target.value }
            }))}
            fullWidth error={!!errors.targetReading} helperText={errors.targetReading}
          />
        </Stack>

        <TextField label="Which colleges are you interested in?" multiline rows={3} fullWidth
          sx={{ marginBottom: 2 }}
          value={formData.colleges}
          onChange={(e) => setFormData(prev => ({
            ...prev,
            colleges: e.target.value
          }))}
          placeholder="List the colleges you're interested in..."
          error={!!errors.colleges} helperText={errors.colleges}
        />

        <TextField label="What major(s) are you considering?" type="text" fullWidth
          value={formData.intendedMajor}
          onChange={(e) => setFormData(prev => ({
            ...prev,
            intendedMajor: e.target.value
          }))}
          placeholder="e.g., Computer Science, Biology, Business..."
        />
      </CardContent>
    </Card>
  </Stack>
  );
  
//Previous question about motivation: What excites you about college? How would a great SAT score help you achieve your dreams?
/*****Getting an error right now:
ResizeObserver loop completed with undelivered notifications.
    at handleError (http://localhost:3000/static/js/bundle.js:126154:58)
      at http://localhost:3000/static/js/bundle.js:126173:7 */
  const renderStep3 = () => (
    <Stack spacing={3} sx={{ width: {xs: '100%', sm: 700}, marginTop: '0 !important', padding: 2, maxWidth: 800}}>
      <Card sx={{ width: '100%' }}>
        <CardContent>
          <Stack spacing={2}>
            
            <Typography variant="body1" sx={{ width: '100%', whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
              What motivates you to study for the SAT? Your effort on the SAT will be the highest impact in college decisions in the shortest time, so how can we help you stay focused and excited?
            </Typography>
            <TextField multiline rows={2} fullWidth value={formData.motivation} 
              sx={{ width: '100%', '& .MuiInputBase-root': { width: '100%' }}}
              onChange={(e) => setFormData(prev => ({ ...prev, motivation: e.target.value }))} 
              placeholder="I'm excited about... It would be awesome if I could...earn $10,000 in scholarships, meet new friends, study abroad to Spain, get a high-paying job..." 
              error={!!errors.motivation} helperText={errors.motivation}
            />
            
            <Typography variant="body1" sx={{ width: '100%'}}>
              Tell me about your strengths and areas you think you could improve. Sharing this information will help design the right questions and guidance for you!
            </Typography>
            
            <TextField label="What are your strengths?" multiline rows={2} fullWidth value={formData.strengths}
              sx={{ marginBottom: 2 }}
              onChange={(e) => setFormData(prev => ({ ...prev, strengths: e.target.value }))}
              placeholder="I'm in Pre-Calculus now and am good at_____. Ex: algebra, quadratic equations...I read a lot, so I'm good at understanding main ideas and have good vocabulary..."
            />
            <TextField label="What areas would you like to improve in?" multiline rows={2} fullWidth value={formData.areasToImprove}
              onChange={(e) => setFormData(prev => ({ ...prev, areasToImprove: e.target.value }))}
              placeholder="I want to get better at _____. Ex: I forgot a lot of Geometry. Word problems are tough...I probably should practice SAT's grammar or punctuation rules..."
            />
            
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
  /*old renderStep3:
  <div className="step-container">
      <Card>
        <label className="input-label">What excites you about college? How would a great SAT score help you achieve your dreams?</label>
        <CardContent>
          <textarea
            value={formData.motivation}
            onChange={(e) => setFormData(prev => ({ ...prev, motivation: e.target.value }))}
            className="motivation-textarea"
            placeholder=" I'm excited about...
            It would be awesome if I could...earn $10,000 in scholarships, meet new friends, study abroad to Spain, get a high-paying job..."
            rows={4}
          />
          {errors.motivation && (
            <p className="error-text">{errors.motivation}</p>
          )}
        </CardContent>
      </Card>
      
      <Card>
        {/*<CardTitle className="your-title-class">Academic Interests</CardTitle>}
        <label className="input-label">Tell me about your strengths and weaknesses so we can design the right questions and guidance for you!</label>
        <CardContent>
          <textarea
            value={formData.strengthsAndWeaknesses}
            onChange={(e) => setFormData(prev => ({ ...prev, strengthsAndWeaknesses: e.target.value }))}
            className="motivation-textarea"
            placeholder=" I'm in Pre-Calculus now and am good at algebra, but I forgot stuff in Geometry...
             I'm a fast reader, but I miss details sometimes, and I don't know the grammar or punctuation very well..."
            rows={4}
          />
          {errors.strengthsAndWeaknesses && (
            <p className="error-text">{errors.strengthsAndWeaknesses}</p>
          )}
        </CardContent>
      </Card>
    </div>
  */

  /*const renderStep4 = () => (
    <div className="step-container">
      <Card>
        <CardTitle className="your-title-class">
          <div className="flex-center">
            <BookOpen className="icon" />
            Preferred Feedback Style
          </div>
        </CardTitle>
        <CardContent>
          <button
            className={`feedback-option ${formData.feedbackStyle === 'detailed' ? 'active' : ''}`}
            data-active={formData.feedbackStyle === 'detailed'}
            onClick={() => setFormData(prev => ({ ...prev, feedbackStyle: 'detailed' }))}
            type="button"
          >
            <div className="feedback-title">Detailed Explanations</div>
            <div className="feedback-description">
              I prefer thorough explanations that break down each step of the solution process
            </div>
          </button>

          <button
            className={`feedback-option ${formData.feedbackStyle === 'concise' ? 'active' : ''}`}
            data-active={formData.feedbackStyle === 'concise'}
            onClick={() => setFormData(prev => ({ ...prev, feedbackStyle: 'concise' }))}
            type="button"
          >
            <div className="feedback-title">Quick Tips</div>
            <div className="feedback-description">
              I prefer brief, to-the-point feedback focusing on key concepts
            </div>
          </button>

          {errors.feedbackStyle && (
            <p className="error-text">{errors.feedbackStyle}</p>
          )}
        </CardContent>
      </Card>      
    </div>
  );*/

  const renderStep5 = () => (
    <Stack spacing={3} sx={{ width: '100%' }}>
      <Card sx={{ width: '100%'}}>
        {/* This has been added to the HomePage as of 2/27/2025 - remove after HomePage is done*/}
        <CardContent >
          <Stack direction="row" alignItems="center" spacing={1}>
            <User className="icon" />
            <Typography variant="h6">Create Your Account</Typography>
          </Stack>
          
          <Stack spacing={2} sx={{marginTop: 1, width: '100%'}}>
            <TextField label="First Name" variant="outlined" fullWidth
              value={formData.firstName}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                firstName: e.target.value
              }))}
              error={!!errors.firstName} helperText={errors.firstName}
            />
            
            <TextField
              label="Email" variant="outlined" fullWidth value={formData.email}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                email: e.target.value
              }))}
              error={!!errors.email} helperText={errors.email}
            />
            
            <TextField label="Password" type="password" variant="outlined" fullWidth
              value={formData.password}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                password: e.target.value
              }))}
              error={!!errors.password} helperText={errors.password}
            />
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );


  const handleNextStep = () => {
    //console.log(`Step ${step} of ${totalSteps}`);
    if (validateStep(step)) {
      if (step === totalSteps) {
        //console.log(`Attempted to handleSubmit()`);
        handleSubmit();
      } else {
        setStep(prev => Math.min(totalSteps, prev + 1));
      }
    }
  };

  const handleSubmit = async () => {
    //console.log('1. Handling user registration submission');
    try {
      //console.log('2. Creating Firebase auth user');

      // Track the completion --> ***In the future have additional actions like sending emails, etc.
      trackPixelEvent(PixelEvents.COMPLETE_CREATE_PROFILE, {
        success: true,
        content_category: 'User Registration',
        content_type: 'form_submission'
      });

      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(
        auth, formData.email, formData.password
      );
      
      //console.log('3. Creating Firestore document');
      const userDocRef = doc(db, 'users', userCredential.user.uid);
      await setDoc(userDocRef, {
        firstName: formData.firstName,
        email: formData.email,
        //
        baseSubscription: false,
        premiumSubscription: false,
        // Profile data
        hasTakenSAT: formData.hasTakenSAT,
        hasTakenACT: formData.hasTakenACT,
        previousSATScores: formData.previousSATScores,
        previousACTScores: formData.previousACTScores,
        targetScores: formData.targetScores,
        colleges: formData.colleges,
        intendedMajor: formData.intendedMajor,
        motivation: formData.motivation,
        strengths: formData.strengths,
        areasToImprove: formData.areasToImprove,
        //feedbackStyle: formData.feedbackStyle,
        //personalInterests: formData.personalInterests,
        // Initialize tracking fields - do I need to add every other field, or will my code add the skill fields as they go through? I think it will.
        grandTotalQuestions: 0,
        completedSATTests: 0,
        totalDaysPracticed: 0,
        numberOfDaysInAStreak: 0,
        activeChallenges: [],
        completedChallenges: [],
        /*currentTestProgress: {
          mathQuestionsAttempted: 0,
          mathCorrectAnswers: 0,
          readingWritingQuestionsAttempted: 0,
          readingWritingCorrectAnswers: 0
        },*/
        skills: {
          algebra: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          circles: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          descriptive_phrases: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          exponential_equation_word_problems: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          exponential_equations: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          function_of_sentence: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          geometry: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          inferences: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          interpreting_graphs: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          linear_equation_word_problems: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          linear_equations: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          linear_inequality_word_problems: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          main_idea: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          percent: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          polynomial_expressions: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          probability: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          pronouns_and_modifiers: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          punctuation: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          quadratic_equation_word_problems: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          quadratic_equations: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          referencing_data: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          statistics: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          supporting_claims: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          synthesizing_notes: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          systems_of_equations: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          tenses: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          transition_words: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          trigonometry: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          two_passages: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          unit_conversions: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          word_choice: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 }
        }
      });

      //console.log('4. Registration complete, navigating to SAT practice page.');
      navigate('/sat');
    } catch (error: any) {
      console.error('Registration error:', error);
      // Handle specific Firebase auth errors
      let errorMessage = 'Registration failed. Please try again.';
      if (error.code === 'auth/email-already-in-use') {
        errorMessage = 'This email is already registered. Please use a different email or try logging in.';
      } else if (error.code === 'auth/invalid-email') {
        errorMessage = 'Invalid email address format.';
      } else if (error.code === 'auth/weak-password') {
        errorMessage = 'Password is too weak. Please use at least 6 characters.';
      }
      
      setErrors(prev => ({
        ...prev, submit: errorMessage
      }));
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderCurrentStep = () => {
    switch (step) {
      case 1:
        return renderStep1();
      case 2:
        return renderStep2();
      case 3:
        return renderStep3();
      case 4:
        return renderStep5();
        //return renderStep4(); //commented out to get rid of Feedback Styles which seems unnecessary
      //case 5:
        //return renderStep5();
      default:
        return null;
    }
  };

  return (
    <Box sx={{ minHeight: '100vh', minWidth: '480px', display: 'flex', justifyContent: 'center', alignItems: 'center', 
      padding: 3}}>
      <Stack spacing={3} sx={{ padding: 3, borderRadius: '1rem', backgroundColor: '#fff', 
        marginTop: {xs: '13.5vh', md: '50px'}, minWidth: {sm: '70vw', md: '60vw', lg: '50vw'},border: '1px solid #f16522'}}>
        <div className="progress-bar-container">
          <div 
            className="progress-bar"
            style={{ width: `${(step / totalSteps) * 100}%` }}
          />
        </div>
        
        <div className="header-container">
          <Typography variant="h4" component="h1" mt={2} gutterBottom>Create Your Learning Profile</Typography>
          <Typography variant="h5" sx={{ color: theme.palette.custom.darkGrey}}>
            Step {step} of {totalSteps}: {
              ['Your Test History', 'Your Goals & Aspirations', 'Your Motivation and Interests', 'Your Learning Style'] //'Personal Interests'
              [step - 1]
            }
          </Typography>
        </div>

        {renderCurrentStep()}

        <Stack direction="row" justifyContent="space-between">
          <Button variant="outlined" onClick={() => setStep(prev => Math.max(1, prev - 1))}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={handleNextStep}>
            {step === totalSteps ? 'Submit' : 'Continue'}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default CreateProfile;


/*HTML before adding Material UI
<div className="profile-container">
        <div className="progress-bar-container">
          <div 
            className="progress-bar"
            style={{ width: `${(step / totalSteps) * 100}%` }}
          />
        </div>
        
        <div className="header-container">
          <h1 className="profile-title">Create Your Learning Profile</h1>
          <h3 className="step-label">
            Step {step} of {totalSteps}: {
              ['Your Test History', 'Your Goals & Aspirations', 'Your Motivation and Interests', 'Your Learning Style'] //'Personal Interests'
              [step - 1]
            }
          </h3>
        </div>

        {renderCurrentStep()}
      </div>
*/




/*Deleted card
<Card>
        <CardTitle className="your-title-class">Areas for Improvement</CardTitle>
        <CardContent>
          <div className="improvement-grid">
            {improvementAreas.map(area => (
              <button
                key={area.id}
                onClick={() => setFormData(prev => ({
                  ...prev,
                  areasForImprovement: prev.areasForImprovement.includes(area.id)
                    ? prev.areasForImprovement.filter(id => id !== area.id)
                    : [...prev.areasForImprovement, area.id]
                }))}
                className={`improvement-button ${
                  formData.areasForImprovement.includes(area.id) ? 'active' : ''
                }`}
                type="button"
              >
                <div className="improvement-icon">{area.icon}</div>
                <div>{area.label}</div>
              </button>
            ))}
          </div>
          {errors.areasForImprovement && (
            <p className="error-text">{errors.areasForImprovement}</p>
          )}
        </CardContent>
      </Card>*/