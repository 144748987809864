import { useState, useContext } from "react"
import Grid from "@mui/material/Grid2"
import { Box, TextField, Switch, FormControlLabel, Typography, Button, Divider } from "@mui/material"
import { UserContext, UserData } from './../UserContext';
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import PaymentIcon from '@mui/icons-material/Payment';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

//import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"

interface EditProfileProps {
    open: boolean;
    onClose: () => void;
  }

export function EditProfile({open, onClose}: EditProfileProps) {
  const { user, updateUserData } = useContext(UserContext);
  const [formData, setFormData] = useState(() => ({
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    personalInterests: user?.personalInterests || '',
    premiumSubscription: user?.premiumSubscription || false,
    hasTakenSAT: user?.hasTakenSAT || false,
    hasTakenACT: user?.hasTakenACT || false,
    hasUpcomingTest: user?.hasUpcomingTest || false,
    previousSATScores: {
      math: user?.previousSATScores?.math || '',
      readingWriting: user?.previousSATScores?.readingWriting || ''
    },
    previousACTScores: {
      math: user?.previousACTScores?.math || '',
      reading: user?.previousACTScores?.reading || '',
      writing: user?.previousACTScores?.writing || '',
      science: user?.previousACTScores?.science || ''
    },
    targetScores: {
      math: user?.targetScores?.math || '',
      readingWriting: user?.targetScores?.readingWriting || ''
    },
    colleges: user?.colleges || '',
    intendedMajor: user?.intendedMajor || '',
    strengths: user?.strengths || '',
    areasToImprove: user?.areasToImprove || '',
    motivation: user?.motivation || ''
  }));

  if (!user) return null;

  // Handle individual field changes
  const handleChange = (field: string, value: any) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Handle nested object changes (like SAT scores)
  const handleNestedChange = (
    parent: 'previousSATScores' | 'previousACTScores' | 'targetScores',
    field: string,
    value: string
  ) => {
    setFormData(prev => ({
      ...prev,
      [parent]: {
        ...prev[parent],
        [field]: value
      }
    }));
  };

  // Handle form submission
  const handleSave = async () => {
    try {
      await updateUserData(formData);
      onClose();
    } catch (error) {
      console.error('Error saving profile:', error);
      // Handle error (show error message to user)
    }
  };

  const handleSubscriptionManagement = () => {
    const stripePortalUrl = 'https://billing.stripe.com/p/login/9AQ6pCgOp7gMbaUdQQ';
    const newWindow = window.open(stripePortalUrl, '_blank', 'noopener,noreferrer');
    //if (!newWindow) alert('Please allow popups to access the subscription management portal');
  };

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition
      sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
    >
        <Fade in={open}>
            <Box sx={{bgcolor: 'background.paper', borderRadius: 2, boxShadow: 24,
            px: 4, py: 2,
            maxWidth: '800px', width: '90%',
            maxHeight: '90vh',
            overflowY: 'auto',
            '&:focus': {outline: 'none'}
            }}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mb: 2}}>
                    <Typography variant="h5">Edit Profile</Typography>
                    {/*<Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end'}}>
                        <Button variant="contained" onClick={onClose}>Cancel</Button>
                        <Button variant="contained" onClick={onClose}>Save</Button>
                    </Box>*/}
                </Box>
                

                <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 2}}>
                        <Box sx={{width: '25%'}}>
                            <TextField fullWidth label="First Name"
                                value={formData.firstName}
                                onChange={(e) => handleChange('firstName', e.target.value)}
                                sx={{
                                "& .MuiOutlinedInput-root": {
                                    backgroundColor: "background.paper",
                                },
                                }}
                            />
                        </Box>
                        <Box sx={{width: '30%'}}>
                            <TextField fullWidth label="Last Name"
                                value={formData.lastName}
                                onChange={(e) => handleChange('lastName', e.target.value)}
                                sx={{
                                "& .MuiOutlinedInput-root": {
                                    backgroundColor: "background.paper",
                                },
                                }}
                            />
                        </Box>
                        <Box sx={{width: '45%'}}>
                            <TextField fullWidth label="Email"
                                type="email"
                                value={formData.email}
                                onChange={(e) => handleChange('email', e.target.value)}
                                sx={{
                                "& .MuiOutlinedInput-root": {
                                    backgroundColor: "background.paper",
                                },
                                }}
                            />
                        </Box>
                    </Box>
                    
                    {/* Read-only subscription info */}
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, px: 2, py: 1, borderRadius: 1,
                        border: '1px solid', borderColor: 'divider'}}>
                        {/* First row: Title and Status */}
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 2}}>
                            <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 500 }}>
                                Current Subscription
                            </Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ display: 'flex', alignItems: 'center'}}>
                            {user.premiumSubscription ? 
                                (<><PaymentIcon fontSize="small" /> Premium</>) 
                                : ('Basic')}
                            </Typography>
                            {/* Premium upgrade message */}
                            {!user.premiumSubscription && (
                            <Button onClick={handleSubscriptionManagement} variant="outlined" size="small" 
                            endIcon={<OpenInNewIcon />}
                            sx={{ml: 2, mr: 2}}>
                            {user.premiumSubscription ? 'Manage Premium' : '✨ Upgrade to Premium for unlimited practice tests, personalized study plans, and more!'}
                            </Button>
                            )}
                        </Box>
                    </Box>


                    {/* Test Information Section */}
                    <Box sx={{display: 'flex', flexDirection: 'row', gap: 2, borderBottom: 1, borderColor: 'divider', pb: 1}}>
                        <Typography variant="h6" gutterBottom>
                        Test Information
                        </Typography>
                        <FormControlLabel control={
                            <Switch checked={formData.hasTakenSAT}
                            onChange={(e) => handleChange('hasTakenSAT', e.target.checked)}
                            />
                          }
                          label="Previous SAT Scores"
                        />
                        <FormControlLabel control={
                            <Switch checked={formData.hasTakenACT}
                                onChange={(e) => handleChange('hasTakenACT', e.target.checked)}
                            />
                          }
                          label="Previous ACT Scores"
                        />
                    </Box>

                    {/* Conditional SAT Score Fields */}
                    {formData.hasTakenSAT && (
                        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                        <TextField
                            fullWidth
                            label="SAT Math Score"
                            value={formData.previousSATScores.math}
                            onChange={(e) => handleNestedChange('previousSATScores', 'math', e.target.value)}
                        />
                        <TextField
                            fullWidth
                            label="SAT Reading/Writing Score"
                            value={formData.previousSATScores.readingWriting}
                            onChange={(e) => handleNestedChange('previousSATScores', 'readingWriting', e.target.value)}
                        />
                        </Box>
                    )}

                    {/* Conditional ACT Score Fields */}
                    {formData.hasTakenACT && (
                        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                        <TextField
                            fullWidth
                            label="ACT Math"
                            value={formData.previousACTScores.math}
                            onChange={(e) => handleNestedChange('previousACTScores', 'math', e.target.value)}
                        />
                        <TextField
                            fullWidth
                            label="ACT Reading"
                            value={formData.previousACTScores.reading}
                            onChange={(e) => handleNestedChange('previousACTScores', 'reading', e.target.value)}
                        />
                        <TextField
                            fullWidth
                            label="ACT Writing"
                            value={formData.previousACTScores.writing}
                            onChange={(e) => handleNestedChange('previousACTScores', 'writing', e.target.value)}
                        />
                        <TextField
                            fullWidth
                            label="ACT Science"
                            value={formData.previousACTScores.science}
                            onChange={(e) => handleNestedChange('previousACTScores', 'science', e.target.value)}
                        />
                        </Box>
                    )}

                    {/* Upcoming Test Information 
                    <Grid sx={{xs: 12}}>
                        <FormControlLabel label="Has Upcoming Test" control={
                            <Switch checked={user.hasUpcomingTest}
                                onChange={(e) => updateUserData({ hasUpcomingTest: e.target.checked })}/>}
                        />
                    </Grid>*/}

                    <Box sx={{display: 'flex', flexDirection: 'row', gap: 2}}>
                        <TextField fullWidth label="Goal Math Score"
                            value={formData.targetScores.math}
                            onChange={(e) => handleNestedChange('targetScores', 'math', e.target.value)}
                        />
                        <TextField fullWidth label="Goal Reading/Writing Score"
                        value={formData.targetScores.readingWriting}
                        onChange={(e) => handleNestedChange('targetScores', 'readingWriting', e.target.value)}
                        />
                    </Box>
                    

                    {/* Academic Information Section */}
                    <Grid sx={{xs: 12}}>
                        <Typography variant="h6" gutterBottom sx={{borderBottom: 1, borderColor: 'divider', pb: 1 }}>
                        Academic Information
                        </Typography>
                    </Grid>
                    <Grid sx={{xs: 12, sm: 6}}>
                    <TextField fullWidth label="Target Colleges"
                        value={formData.colleges}
                        onChange={(e) => handleChange('colleges', e.target.value)}
                        sx={{
                        "& .MuiOutlinedInput-root": {
                            backgroundColor: "background.paper",
                        },
                        }}
                    />
                    </Grid>
                    <Grid sx={{xs: 12, sm: 6}}>
                    <TextField fullWidth label="Intended Major"
                        value={formData.intendedMajor}
                        onChange={(e) => handleChange('intendedMajor', e.target.value)}
                        sx={{
                        "& .MuiOutlinedInput-root": {
                            backgroundColor: "background.paper",
                        },
                        }}
                    />
                    </Grid>
                    <Grid sx={{xs: 12}}>
                    <TextField fullWidth multiline rows={2}
                        label="Strengths"
                        value={formData.strengths}
                        onChange={(e) => handleChange('strengths', e.target.value)}
                        sx={{
                        "& .MuiOutlinedInput-root": {
                            backgroundColor: "background.paper",
                        },
                        }}
                    />
                    </Grid>
                    <Grid sx={{xs: 12}}>
                    <TextField fullWidth multiline rows={2}
                        label="Areas to Improve"
                        value={formData.areasToImprove}
                        onChange={(e) => handleChange('areasToImprove', e.target.value)}
                        sx={{
                        "& .MuiOutlinedInput-root": {
                            backgroundColor: "background.paper",
                        },
                        }}
                    />
                    </Grid>
                    
                    <Grid sx={{xs: 12}}>
                    <TextField fullWidth label="Motivation"
                        value={formData.motivation}
                        onChange={(e) => handleChange('motivation', e.target.value)}
                        sx={{
                        "& .MuiOutlinedInput-root": {
                            backgroundColor: "background.paper",
                        },
                        }}
                    />
                    </Grid>
                    <Grid sx={{xs: 12}}>
                        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end'}}>
                            <Button variant="outlined" onClick={onClose}>Cancel</Button>
                            <Button variant="contained" onClick={handleSave}>Save</Button>
                        </Box>
                    </Grid>
                </Box>
            </Box>
        </Fade>
    </Modal>
  )
}