import React, {FC, useState, useEffect, useRef, useContext} from 'react'; //useContext
import {BrowserRouter as Router, Route, Routes, useLocation, Navigate} from 'react-router-dom';
import './index.css';
import './AppStyles.css';
import HomePage from './components/HomePage';
import SecondPage from './components/SecondPage';
import Login from './components/Login';
import { UserProvider, UserContext } from './components/UserContext'; //shares user data from Firestore throughout Components.
import CreateProfile from './components/CreateProfile';
import { ThemeProvider } from '@mui/material/styles'; //Material UI Styline
import CssBaseline from '@mui/material/CssBaseline';
import theme from './muiTheme';
import { Box,  Typography,AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, ListItemIcon, useMediaQuery, Button} from '@mui/material'; 
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonIcon from '@mui/icons-material/Person';  
import { Link as RouterLink } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import { ExpandedDashboard } from './components/dashboard-components/ExpandedDashboard';
import { trackMainNavClick } from './utils/meta/metaPixel';


const NavigationContent: FC = () => {
  const location = useLocation();
  const currentPage = location.pathname.replace('/', '') || 'Home';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const {user} = useContext(UserContext);

  // Navigation items
  const navItems = [
    { text: 'Home', path: '/', icon: <HomeIcon />, onClick: () => trackMainNavClick(currentPage, 'Home') },
    { text: 'SAT Practice', path: '/sat', icon: <SchoolIcon />, onClick: () => trackMainNavClick(currentPage, 'SAT Practice') },
    /*user 
      ? { text: 'View Profile', path: '/view-profile', icon: <PersonIcon /> }
      : { text: 'Create Profile', path: '/create-profile', icon: <PersonAddIcon /> }*/
      { text: 'View Profile', path: '/view-profile', icon: <PersonIcon />, onClick: () => trackMainNavClick(currentPage, 'View Profile') },
      { text: 'Create Profile', path: '/create-profile', icon: <PersonAddIcon />, onClick: () => trackMainNavClick(currentPage, 'Create Profile') }
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const toggleDropdown = () => {
    setShowLogin(!showLogin);
  };

  const handleLoginSuccess = () => {
    setShowLogin(false); // Close the dropdown on successful login
  };

  // Drawer content
  const drawer = (
    <List>
      {navItems.map((item) => (
        <ListItem key={item.text} component={RouterLink} 
          to={item.path} onClick={() => setMobileOpen(false)}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItem>
      ))}
    </List>
  );

  return (
    <>
      <AppBar component="header" position="absolute"
            sx={{
              zIndex: 2,
              color: 'white', // White text
              '& .MuiToolbar-root': { 
                fontSize: '1.5rem', 
                position: 'relative', 
                zIndex: 2 
              },
              boxShadow: '0 2px 4px rgba(0,0,0,0.5)',
              borderRadius: 0
            }}
          >

              <Toolbar sx={{ gap: 2, height: '7vh', minHeight: { xs: '56px', sm: '64px', md: '72px' } }}>  

                {/* Logo Banner in top left corner */}
                  <Box component="img" src="/images/logo-banner.png" alt="Next Step Education Logo"
                    sx={{height: '5vh', maxHeight: '40px', width: 'auto', display: { xs: isMobile ? 'none' : 'block', sm: 'block' }}} 
                  />              
                
                {/* Mobile Navigation */}
                {isMobile && (
                  <IconButton color="inherit" aria-label="open drawer" edge="start"
                    onClick={handleDrawerToggle} sx={{ mr: 2 }} >
                    <MenuIcon />
                  </IconButton>
                )}
                
                {/* Desktop Navigation */}
                {!isMobile && (
                  <Box sx={{ display: 'flex', gap: 2, marginLeft: 'auto'}}>
                    {navItems.map((item) => (
                      <Button key={item.text} component={RouterLink} to={item.path} 
                        color="inherit" startIcon={item.icon}
                        sx={{textAlign: 'center', fontSize: { xs: '1rem', sm: '1.2rem', md: '1.4rem', lg: '1.5rem'},
                          '& .MuiButton-startIcon': {marginRight: 1},
                          position: 'relative',  // Required for absolute positioning of pseudo-element
                          '&::after': {
                            content: '""', position: 'absolute', width: '0', height: '2px', bottom: 0, left: '50%',
                            backgroundColor: 'currentColor', transition: 'all 0.2s ease',
                          },
                          '&:hover::after': {width: '100%', left: '0'},
                          '&:hover': {backgroundColor: 'rgba(255, 255, 255, 0.2)'},
                        }}
                        >
                        {item.text}
                      </Button>
                    ))}
                  </Box>
                )}

                {/* Login Button */}
                <Box sx={{ ml: { xs: 'auto', sm: 2 } }}>
                  <Login onLoginSuccess={handleLoginSuccess} 
                    toggleDropdown={toggleDropdown} 
                    showLogin={showLogin} />
                </Box>
              </Toolbar>
            </AppBar>

            {/* Mobile Navigation Drawer */}
            <Drawer variant="temporary" anchor="left" open={mobileOpen} onClose={handleDrawerToggle}
              ModalProps={{keepMounted: true}} // Better open performance on mobile.
              sx={{display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
              }}
            >
              {drawer}
            </Drawer>
    </>
  )
}

const App: FC = () => {
  
  //UserProvider wraps everything, making the user context available everywhere.
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <CssBaseline /> {/* Provides consistent baseline styles - haven't fully vetted this, so double check sometime after 12/10/2024*/}
        
        <Router>
          <Box sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
            <NavigationContent />

            <Box component="main" sx={{flexGrow: 1, width: '100%', margin: 0, padding: 0}}>{/*flexGrow: 1 makes the box take up the remaining space, so footer is at the bottom*/}
              <Routes> 
                <Route path ="/" element={<HomePage />} />
                <Route path ="/sat" element={<SecondPage />} />
                <Route path ="/create-profile" element={<CreateProfile />} />
                <Route path="/view-profile" element={<ExpandedDashboard />} />
                {/*<Route path="/view-profile" element={user ? <ViewProfile /> : <Navigate to="/create-profile" replace />} />*/}
                {/*<Route path="/view-profile" element={<ViewProfile />} />*/}
              </Routes>
            </Box>
                

            <Box component="footer" sx={{backgroundColor: theme.palette.custom.lightGrey, color: theme.palette.custom.darkGrey, 
              mt: 'auto', padding: { xs: '1rem', sm: '1.5rem' }, minHeight: '10vh'}}>
                <Typography variant="body2" sx={{textAlign: 'left', marginLeft: '3vw'}}>© 2024 Next Step Education AI Tutoring. All rights reserved.</Typography>
                <Typography variant="body2" sx={{textAlign: 'left', marginLeft: '3vw'}}>SAT® is a registered trademark of the College Entrance Examination Board™. The College Entrance Examination Board™ does not endorse, nor is it affiliated in any way with the owner or any content of this website.</Typography>
            </Box>

          </Box>
        </Router>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;


//import reportWebVitals from './reportWebVitals.js';