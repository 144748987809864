import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from 'lucide-react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { Stack, Typography, Button, TextField, Card, CardContent, Paper, useMediaQuery, useTheme } from '@mui/material';
import { trackPixelEvent, PixelEvents } from '../utils/meta/metaPixel';

type SimpleFormData = {
  firstName: string;
  email: string;
  password: string;
};

interface SimpleFormErrors {
  firstName?: string;
  email?: string;
  password?: string;
  submit?: string;
}

const SimpleProfileForm: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 
  const [formData, setFormData] = useState<SimpleFormData>({
    firstName: '',
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState<SimpleFormErrors>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = (): boolean => {
    const newErrors: SimpleFormErrors = {};

    // Email validation
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    
    // Password validation
    if (!formData.password) {
      newErrors.password = 'Password is required';
    } else if (formData.password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters';
    }
    
    // First name validation
    if (!formData.firstName.trim()) {
      newErrors.firstName = 'First name is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    
    setIsSubmitting(true);
    
    try {
      /* Track the registration attempt
      trackPixelEvent(PixelEvents.COMPLETE_CREATE_PROFILE, {
        success: true,
        content_category: 'User Registration',
        content_type: 'form_submission'
      });*/

      trackPixelEvent(PixelEvents.START_TRIAL, {
        content_name: 'Free Trial',
        content_category: 'SAT Tutoring',
        content_type: 'product_group'
      });

      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(
        auth, formData.email, formData.password
      );
      
      const userDocRef = doc(db, 'users', userCredential.user.uid);
      await setDoc(userDocRef, {
        firstName: formData.firstName,
        email: formData.email,
        // Default values for other required fields
        baseSubscription: false,
        premiumSubscription: false,
        hasTakenSAT: false,
        hasTakenACT: false,
        previousSATScores: { math: '', readingWriting: '' },
        previousACTScores: { math: '', reading: '', writing: '', science: '' },
        targetScores: { math: '', readingWriting: '' },
        colleges: '',
        intendedMajor: '',
        motivation: '',
        strengths: '',
        areasToImprove: '',
        // Initialize tracking fields
        grandTotalQuestions: 0,
        completedSATTests: 0,
        totalDaysPracticed: 0,
        numberOfDaysInAStreak: 0,
        activeChallenges: [],
        completedChallenges: [],
        // Initialize skills with default values (simplified version)
        skills: {
          algebra: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          // Add other skills with the same structure as needed
        }
      });

      navigate('/sat');
    } catch (error: any) {
      console.error('Registration error:', error);
      // Handle specific Firebase auth errors
      let errorMessage = 'Registration failed. Please try again.';
      if (error.code === 'auth/email-already-in-use') {
        errorMessage = 'This email is already registered. Please use a different email or try logging in.';
      } else if (error.code === 'auth/invalid-email') {
        errorMessage = 'Invalid email address format.';
      } else if (error.code === 'auth/weak-password') {
        errorMessage = 'Password is too weak. Please use at least 6 characters.';
      }
      
      setErrors(prev => ({
        ...prev, submit: errorMessage
      }));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Paper elevation={4} sx={{ px: { xs: 0, sm: 1}, py: 0, width: '100%', maxWidth: {xs: 650, sm: 450}, backgroundColor: 'rgba(255, 255, 255, 1)' }}>
      <Card sx={{ width: '100%', backgroundColor: 'transparent', boxShadow: 'none' }}>
        <CardContent sx={{p: 2}}>
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} sx={{ mb: 0 }}>
              {/*  <User className="icon" /> */}
              <Typography variant="h6">Sign Up & Get 1-Month Free!</Typography>
            </Stack>
          {/* isMobile ? null : (
            <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 0 }}>
              <User className="icon" />
              <Typography variant="h6">Create Your Account</Typography>
            </Stack>
          )} */}
          
          <Stack spacing={2} sx={{ marginTop: 1, width: '100%' }}>
            <TextField 
              label="First Name" 
              variant="outlined" 
              fullWidth
              value={formData.firstName}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                firstName: e.target.value
              }))}
              error={!!errors.firstName} 
              helperText={errors.firstName}
            />
            
            <TextField
              label="Email" 
              variant="outlined" 
              fullWidth 
              value={formData.email}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                email: e.target.value
              }))}
              error={!!errors.email} 
              helperText={errors.email}
            />
            
            <TextField 
              label="Password" 
              type="password" 
              variant="outlined" 
              fullWidth
              value={formData.password}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                password: e.target.value
              }))}
              error={!!errors.password} 
              helperText={errors.password}
            />
            
            {errors.submit && (
              <Typography color="error" variant="body2">
                {errors.submit}
              </Typography>
            )}
            
            <Button 
              variant="contained" 
              color="secondary" 
              size="large" 
              fullWidth
              onClick={handleSubmit}
              disabled={isSubmitting}
              sx={{ py: 1.5, fontSize: '1.1rem' }}
            >
              Start Free Trial Now!
            </Button> {/* Create Profile & Start Now  - previous text here*/}
          </Stack>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default SimpleProfileForm;