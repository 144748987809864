import React, { useContext } from 'react'
import { Box, Button, Typography, LinearProgress, Tooltip, Paper, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { UserContext, SkillData, categories, skillFrequency, SkillFrequency } from "../UserContext";
import Grid from '@mui/material/Grid2';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Card, CardHeader, CardContent} from '@mui/material';
import Grid2 from '@mui/material/Grid2';
//import theme from "../../muiTheme"

/*type ImprovementArea = {
  skill: string
  attempted: number
  percentCorrect: number
  frequency: number
  category: 'Math' | 'Reading and Writing'
  suggestedChallenge: string
}*/
interface ImprovementAreasProps {
  onAddChallenge: (challenge: string) => void;
  //For calling startQuestionSeries within QuestionGeneratorAndSkillButtons.tsx:
  onStartChallenge?: (skill: string, difficulty: 'Easy' | 'Medium' | 'Hard') => void;
  containerStyle?: React.CSSProperties; // Optional styling for the container
}

interface Challenge {
  id: string;
  text: string;
  completed: boolean;
  createdAt: Date;
}

const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  justifyContent: 'flex-start',
  fontSize: '0.75rem',
  height: 'auto',
  padding: '0.5rem 0.75rem',
  textTransform: 'none',
  lineHeight: 1.5,
  '& strong': {
    fontWeight: 'bold',
  },
}));

const ProgressContainer = styled(Box)(({ theme }) => ({
  height: '0.5rem',
  width: '100%',
  backgroundColor: '#e0e0e0',
  borderRadius: '0.25rem',
  position: 'relative',
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '100%',
  borderRadius: '0.25rem',
  backgroundColor: '#e0e0e0',
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.custom.green,
  },
}));

const GoalIndicator = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  borderLeft: `1px solid ${theme.palette.primary.main}`,
}));

interface Props {
  category: 'Math' | 'Reading and Writing';  // 'Math' | 'Reading and Writing'
  onAddChallenge: (challenge: string) => void;
}

export function ImprovementAreasContainer({ onAddChallenge, containerStyle }: ImprovementAreasProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'row'}, justifyContent: 'space-evenly', gap: 3, ...containerStyle }}>
      <Grid2 sx={{flex: 1, gridColumn: { xs: "span 12", md: "span 6" } }}>
        <Card sx={{width: '100%', p: 1}}>
          <CardHeader 
            sx={{ py: 1 }} 
            title={<Typography variant="h6" color="primary.main">Top 3 Reading & Writing Skills to Improve</Typography>} 
          />
          <CardContent sx={{ py: 0 }}>
            <ImprovementAreasVisualization 
              category="Reading and Writing" 
              onAddChallenge={onAddChallenge}
            />
          </CardContent>
        </Card>
      </Grid2>
      <Grid2 sx={{flex: 1, gridColumn: { xs: "span 12", md: "span 6" } }}>
        <Card sx={{width: '100%', p: 1}}>
          <CardHeader 
            sx={{ py: 1 }} 
            title={<Typography variant="h6" color="primary.main">Top 3 Math Skills to Improve</Typography>} 
          />
          <CardContent sx={{ py: 0 }}>
            <ImprovementAreasVisualization 
              category="Math" 
              onAddChallenge={onAddChallenge} 
            />
          </CardContent>
        </Card>
      </Grid2>
    </Box>
  );
}

export function ImprovementAreasVisualization({ category, onAddChallenge }: Props) {
  const { user, updateUserData } = useContext(UserContext);
  const [challenges, setChallenges] = React.useState<Challenge[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedChallengeId, setSelectedChallengeId] = React.useState<string | null>(null);

  // Move useEffect to the top with other hooks
  React.useEffect(() => {
    if (user) {
      const allChallenges = [
        ...(user.activeChallenges || []).map(text => ({
          id: crypto.randomUUID(),
          text,
          completed: false,
          createdAt: new Date()
        })),
        ...(user.completedChallenges || []).map(text => ({
          id: crypto.randomUUID(),
          text,
          completed: true,
          createdAt: new Date()
        }))
      ];
      setChallenges(allChallenges);
    }
  }, [user]);

  // Inside your component, add this helper function after the useEffect
  const filteredChallenges = React.useMemo(() => {
    return challenges
      .filter(challenge => {
        // Check if the challenge text contains any skill from the current category
        return categories[category].some(skill => challenge.text.includes(skill));
      })
      .sort((a, b) => {
        // Keep your existing sorting logic
        if (a.completed === b.completed) {
          return b.createdAt.getTime() - a.createdAt.getTime();
        }
        return a.completed ? 1 : -1;
      });
  }, [challenges, category]);

  if (!user) return <Typography variant="body2">Create your profile or log in to see your improvement areas!</Typography>;

  // 1. Calculate performance for each skill
  const calculateSkillPerformance = (skillKey: string) => {
    //console.log(`Calculating performance for skill: ${skillKey}`);
    const skillData = user.skills[skillKey];
    if (!skillData) return 0;
    //console.log(`Skill Data: ${skillData}`);
    
    const attempted = skillData.overallQuestionsAttempted || 0;
    //console.log(`Attempted: ${attempted}`);
    const correct = skillData.overallQuestionsCorrect || 0;
    //console.log(`Correct: ${correct}`);
    return attempted > 0 ? (correct / attempted) * 100 : 0;
  };

  // 2. Get priority score (frequency/performance ratio)
  /*TODO: Revisit this to make it more accurate based on user's usage of the app
  - If it's been many days since practicing a skill, gradually increase priority to promote spaced repetition
  - Consider differences in lower initial starting skill students vs. higher achieving so this works for both.
  */
  const getPriorityScore = (skillName: string, performance: number) => {
    const frequency = skillFrequency[skillName as keyof SkillFrequency] || 0.02;
    const performanceScore = performance === 0 ? 20 : Math.max(20, performance); //20 is the base if they haven't attempted any questions
    const frequencyScore = frequency * 100;
    return frequencyScore * (1 - (performanceScore / 100));
  };

  // 3. Suggest difficulty for the user to practice based on their performance
  const getSuggestedDifficulty = (performance: number): 'Easy' | 'Medium' | 'Hard' => {
    if (performance < 40) return 'Easy';
    if (performance < 70) return 'Medium';
    return 'Hard';
  };

  // 4. Generate 3 improvement areas by sorting by priority score
  const topSkills = categories[category]
    .map(skillName => {
      const skillKey = skillName.toLowerCase().replace(/ /g, '_');
      const performance = calculateSkillPerformance(skillKey);
      //console.log(`Performance for ${skillName}: ${performance}`);
      const frequency = (skillFrequency[skillName] || 0.02) * 100; //skillFrequency is "Function of Sentence" vs. "function-of-sentence" format
      
      return {
        skillName,
        performance,
        frequency,
        priorityScore: getPriorityScore(skillName, performance),
        suggestedDifficulty: getSuggestedDifficulty(performance)
      };
    })
    .sort((a, b) => b.priorityScore - a.priorityScore)
    .slice(0, 3);

  //************************************************************************************* */
  // 5. Generate challenge text ****Super important part to revisit this based on user's usage of the app!!
  //Just randomize the number of questions in a range (4-6) for funzies? Could also depend on when I finish the AI summary of the question sets
  //These challenges can also be dynamically generated by an AI based on the user's performance and the question sets
  const generateChallenge = (skill: typeof topSkills[0]) => {
    const questionCount = skill.performance === 0 ? 4 : 5 //4 questions at easy, 5 at medium
    
    return `Complete ${questionCount} ${skill.skillName} questions at ${skill.suggestedDifficulty} difficulty`;
  };
  
  const getGoalPercentage = (targetScore: number) => {
    // Example formula: (targetScore - currentScore) / totalPossibleScore * 100
    const totalPossibleScore = parseInt(user.targetScores.readingWriting) + parseInt(user.targetScores.math) || 1600; // Assuming SAT total score
    //console.log(`Goal Percentage = targetScore (${targetScore}) / totalPossibleScore (${totalPossibleScore})`);
    return (targetScore  / totalPossibleScore) * 100;
  };

  // Get combined goal score
  const goal = user.targetScores.readingWriting && user.targetScores.math
    ? parseInt(user.targetScores.readingWriting) + parseInt(user.targetScores.math)
    : null;

  const handleAddChallenge = async (skill: typeof topSkills[0]) => {
    const newChallenge = generateChallenge(skill);
    
    try {
      // Update local state
      setChallenges(prev => [{ 
        id: crypto.randomUUID(),
        text: newChallenge,
        completed: false,
        createdAt: new Date()
      }, ...prev]);
      
      // Update via UserContext
      if (user) {
        await updateUserData({
          activeChallenges: [...(user.activeChallenges || []), newChallenge]
        });
      }
      
      onAddChallenge(newChallenge);
    } catch (error) {
      console.error('Error adding challenge:', error);
      // Revert local state if update fails
      setChallenges(prev => prev.filter(c => c.text !== newChallenge));
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, challengeId: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedChallengeId(challengeId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedChallengeId(null);
  };

  const handleCompleteChallenge = async () => {
    const challenge = challenges.find(c => c.id === selectedChallengeId);
    if (!challenge || !user) return;

    try {
      // Update local state
      setChallenges(prev =>
        prev.map(c =>
          c.id === selectedChallengeId ? { ...c, completed: true } : c
        )
      );
      
      // Update via UserContext
      await updateUserData({
        activeChallenges: user.activeChallenges.filter(c => c !== challenge.text),
        completedChallenges: [...(user.completedChallenges || []), challenge.text]
      });
      
      handleMenuClose();
    } catch (error) {
      console.error('Error completing challenge:', error);
      // Revert local state if update fails
      setChallenges(prev =>
        prev.map(c =>
          c.id === selectedChallengeId ? { ...c, completed: false } : c
        )
      );
    }
  };

  const handleRemoveChallenge = async () => {
    const challenge = challenges.find(c => c.id === selectedChallengeId);
    if (!challenge || !user) return;

    try {
      // Update local state
      setChallenges(prev => prev.filter(c => c.id !== selectedChallengeId));
      
      // Update via UserContext
      await updateUserData({
        activeChallenges: user.activeChallenges.filter(c => c !== challenge.text),
        completedChallenges: user.completedChallenges.filter(c => c !== challenge.text)
      });
      
      handleMenuClose();
    } catch (error) {
      console.error('Error removing challenge:', error);
      // Revert local state if update fails
      if (challenge) {
        setChallenges(prev => [...prev, challenge]);
      }
    }
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, px: 0, width: '100%'}}>
      
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}>
        {topSkills.map((skill, index: number) => (
          <Box key={skill.skillName} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {/*StyledButton looks like: "Complete 5 Linear Equations questions at Medium difficulty - 4.5% frequency on test"*/}
            <StyledButton 
              variant="outlined"
              //Temporarily disabled the clicking on 2/7 while it's in development.
              //Eventually this onClick should call startQuestionSeries in SkillButtonsAndQuestionGenerator.tsx
              //onClick={() => handleAddChallenge(skill)}
            >
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, alignItems: 'center' }}>
                <Typography component="span">Complete 5{' '}</Typography>
                <Typography component="span" sx={{ fontWeight: 'bold' }}>{skill.suggestedDifficulty} {skill.skillName}</Typography>{' '}
                <Typography component="span">questions:{' '}</Typography>
                <Typography component="span">{skill.frequency.toFixed(1)}% frequency on test</Typography>
              </Box> 
            </StyledButton>
            
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
              <Typography variant="caption" color="text.secondary">
                {typeof skill.performance === 'number' 
                  ? `${skill.performance.toFixed(1)}% correct`
                  : '0% correct'}
              </Typography>
                <Typography variant="caption" color="text.secondary">
                  {goal ? `${getGoalPercentage(goal)}% needed for ${goal}` : ''}
                </Typography>
              </Box>
              <ProgressContainer>
              <StyledLinearProgress variant="determinate"
                value={typeof skill.performance === 'number' 
                  ? Math.min(100, Math.max(0, skill.performance))  // Clamp between 0-100
                  : 0}
              />
                {goal ? (
                <Tooltip title={`Goal: ${getGoalPercentage(goal)}% correct required to meet SAT score goal`}>
                  <GoalIndicator
                    style={{ left: `${Number(getGoalPercentage(goal))}%` }}
                  />
                </Tooltip>
                ) : null}
              </ProgressContainer>
            </Box>
          </Box>
        ))}
      </Box>
          

      {/* *****************Revisit and implement this later Challenge Journey ******************** 
      <Box sx={{ mt: 2, px: 2 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>Your Challenge Journey</Typography>
        
        <Box sx={{ 
          position: 'relative',
          maxHeight: '400px',
          overflow: 'hidden',
          '&:hover': {
            overflow: 'overlay', // Show scrollbar on hover
          },
          // Add padding-right to account for scrollbar width
          pr: 1,
          mr: -1,
          // Add custom scrollbar styling
          '&::-webkit-scrollbar': {
            width: '8px',
            backgroundColor: 'transparent',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.2)',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: 'rgba(0,0,0,0.3)',
            },
          },
        }}>
          {/* Timeline line needs to be inside scrollable container 
          <Box sx={{
            position: 'absolute',
            left: 4,
            top: 0,
            bottom: 0,
            width: 2,
            bgcolor: 'grey.300',
          }} />
          
          {filteredChallenges.map(challenge => (
            <Box
              key={challenge.id}
              sx={{
                display: 'flex',
                mb: 3,
                position: 'relative',
                pl: 4
              }}
            >
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  position: 'absolute',
                  left: 0,
                  top: 6,
                  bgcolor: challenge.completed ? 'success.main' : 'primary.main',
                }}
              />
              
              <Paper
                sx={{
                  p: 2,
                  flex: 1,
                  width: 'calc(100% - 16px)',
                  borderRadius: 2,
                  bgcolor: challenge.completed ? 'success.light' : 'background.paper',
                  border: 1,
                  borderColor: challenge.completed ? 'success.main' : 'primary.main',
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: challenge.completed ? 'success.light' : 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center'
                }}>
                  <Typography
                    variant="subtitle1"
                    color={challenge.completed ? 'success.main' : 'primary.main'}
                  >
                    {challenge.text}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Chip
                      label={challenge.completed ? 'Completed' : 'In Progress'}
                      size="small"
                      color={challenge.completed ? 'success' : 'primary'}
                      sx={{ mr: 1 }}
                    />
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMenuOpen(e, challenge.id);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Paper>
            </Box>
          ))}
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem 
          onClick={handleCompleteChallenge}
          disabled={challenges.find(c => c.id === selectedChallengeId)?.completed}
        >
          Mark as Completed
        </MenuItem>
        <MenuItem 
          onClick={handleRemoveChallenge}
          sx={{ color: 'error.main' }}
        >
          Remove Challenge
        </MenuItem>
      </Menu>*/}
    </Box>
  )
}