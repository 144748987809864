import React from 'react'; //{useState, useEffect}
//import ChatComponent from './ChatComponent';
import { useNavigate } from 'react-router-dom';
//import { getAuth, setPersistence, browserLocalPersistence, createUserWithEmailAndPassword } from 'firebase/auth';
//import { setDoc, doc } from 'firebase/firestore';
//import { auth, db } from '../firebase'; // importsinstance of db from firebase.ts. 
//import CreateProfile, { FormData as ProfileFormData} from './CreateProfile';
import './HomePage.css';
import { Box, Container,Typography, Button, List, ListItem, ListItemIcon, ListItemText, Paper, useTheme, useMediaQuery, CardContent, Stack, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
//import StarIcon from '@mui/icons-material/Star';
import CheckIcon from '@mui/icons-material/Check';
import { User } from 'lucide-react';
import { trackPixelEvent, PixelEvents, trackHomePageCTAButton } from '../utils/meta/metaPixel';
import CreateSimpleProfile from './CreateSimpleProfile';
import FAQSection from './FAQ';

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));



  /* For the main CTA button in the Hero Section
  const handleHomePageCTAButtonClick = () => {
    trackHomePageCTAButton('HomePage', 'SAT', 'Start Your SAT Journey Now!');
    navigate('/sat');
  };*/

  const handleBenefitSectionCTAButtonClick = () => {
    trackPixelEvent('HomePageHeroCTA', {
      content_name: 'Hero Section CTA Click',
      content_category: 'SAT Tutoring',
      content_type: 'product_group',
      value: 0, // Optional: if there's a monetary value associated
    });
    
    // Smooth scroll to top of page or navigate as needed
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    
    // Optional: If you want to focus on the form after scrolling
    setTimeout(() => {
      const formElement = document.getElementById('signup-form');
      if (formElement) {
        formElement.focus();
      }
    }, 1000);
  };

  const handleFAQSectionCTAButtonClick = () => {
    trackPixelEvent('HomePageFAQCTA Free Trial Button', {
      content_name: 'FAQ Section CTA Click',
      content_category: 'SAT Tutoring',
      content_type: 'product_group',
      value: 0, // Optional: if there's a monetary value associated
    });
    
    // Smooth scroll to top of page or navigate as needed
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    
    // Optional: If you want to focus on the form after scrolling
    setTimeout(() => {
      const formElement = document.getElementById('signup-form');
      if (formElement) {
        formElement.focus();
      }
    }, 1000);
  };

  const handleCheckoutClick = (tier: any) => {
    if (tier.price === "Free") {
      
      trackPixelEvent(PixelEvents.START_TRIAL, {
        content_name: 'Click Free Trial Button in Choose Your Study Path Section',
        content_category: 'SAT Tutoring',
        content_type: 'product_group',
      });
      //navigate('/sat'); //what I used to do before 2/28/2025

      // Smooth scroll to top of page
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      
      // Optional: If you want to focus on the form after scrolling
      setTimeout(() => {
        const formElement = document.getElementById('signup-form'); // Add this ID to your form
        if (formElement) {
          formElement.focus();
        }
      }, 1000); // Delay to allow scroll to complete
    } else if (tier.price === "$29.99/month")
    {
      //******************3/4/2025 - Not sure if the updated Pixel events are working - need to test. */
      trackPixelEvent(PixelEvents.INITIATE_STRIPE_CHECKOUT_MONTHLY, {
        content_name: tier.title,
        content_category: 'SAT Tutoring',
        content_type: 'product_group',
        currency: 'USD',
        value: parseFloat(tier.price.replace('$', '').replace('/month', '')),
      });
      window.open( //Can change whether to add a 3, 7, or 30 day free trial within Stripe checkout page.
        "https://buy.stripe.com/fZe17l8TEef006I002", //$29.99/month plan, updated as of 3/24/2025. 
        "_blank",
        "noopener,noreferrer"
      );
    } 
    else {
      trackPixelEvent(PixelEvents.INITIATE_STRIPE_CHECKOUT_LIFETIME, {
        content_name: tier.title,
        content_category: 'SAT Tutoring',
        content_type: 'product_group',
        currency: 'USD',
        value: parseFloat(tier.price.replace('$', '').replace('/month', '')),
      });
      window.open(
        "https://buy.stripe.com/6oEg2fb1Mef0bPq9AB",
        "_blank",
        "noopener,noreferrer"
      )
    }
  };

  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          backgroundImage: 'url(/images/MIT-lawn-background.jpg)', backgroundSize: 'cover',
          backgroundPosition: 'center 90%', backgroundAttachment: {xs: 'scroll', md: 'fixed'},
          backgroundRepeat: 'no-repeat',
          pt: {xs: '60px', sm: '120px', md:'72px'}, 
          pb: {sm: '52px', md: '0px'},
          px: 0, mx: 0,
          width: '100%', 
          color: 'white',
          flexShrink: 0,
          minHeight: {xs: '80vh', sm: '70vh'}, //testing reduced height since need to fit space for benefit section below
          //minHeight: {xs: '110vh',sm: '100vh'},
          position: 'sticky',
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
          '&::before': {
            content: '""', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }
        }}
      >
        <Container sx={{ maxWidth: '1400px !important', position: 'relative', zIndex: 1, width: '100%'}}>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row'}} width="100%" justifyContent="center" 
            sx={{mb: 2,alignItems: 'center', gap: 2}}>
            <Box sx={{flex: 3, textAlign: 'center'}}>
              <Typography variant="h2" component="h1" color="white"
                sx={{ fontWeight: 'bold', mt: 2, mb: 2, fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }}}
              >
                Higher SAT Scores. Faster. Easier.
              </Typography>
              <Typography variant="h5" color="white"
                sx={{ mb: 2, opacity: 0.9, fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.5rem' }}}
              >
                Boost your SAT score by 100+ points in 1 month with our innovative AI-driven SAT Program.
              </Typography>
              
              { isLargeScreen ? null : (
              <Box sx={{ textAlign: 'left', mt: 2, px: { xs: 2, md: 4 } }}>
                {[
                  "For 6 years, we've helped students improve by 100-250 points", //Based on 6 years of live SAT tutoring success and 
                  "Designed with modern neuroscience research to maximize learning",
                  "Trusted by students, parents, and school districts nationwide"
                  
                  /*"Learn strategies for each question type",
                  "Get personalized AI feedback",
                  "Track progress with the optimal improvement plan"*/
                ].map((item, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', mb: 2,  }}>
                    <Box component="span" sx={{ color: 'white', mr: 1.5, fontSize: '1.5rem', lineHeight: 1.5, mt: 0.5 }}>
                      •
                    </Box>
                    <Typography variant="body1" color="white" 
                      sx={{ fontSize: { xs: '1rem', md: '1.25rem' }, lineHeight: 1.5 }}>
                      {item}
                    </Typography>
                  </Box>
                ))}
              </Box>
              )}
              
              
            </Box>
            <Box sx={{flex: 2, width: {xs: '95%', md: 'auto'}, display: 'flex', justifyContent: 'center'}}>
              <CreateSimpleProfile />
            </Box>
            
          </Box>
          
        </Container>
      </Box>


      {/* Testimonials Section */}
      <Box sx={{ backgroundColor: theme.palette.background.default, py: 6 }}>
        <Container>
          <Typography variant="h3" component="h2" align="center" sx={{ mb: 4, color: theme.palette.text.primary }}>
            Success Stories from Our Students
          </Typography>
          <Box sx={{ 
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 3,
            alignItems: 'stretch'
          }}>
            {[
              {
                quote: "This course was super helpful. My SAT score went up 220 points in 6 weeks!",
                author: "Anna S., Junior, 2023 former student"
              },
              {
                quote: "I loved this course. It helped me be more prepared because I wasn't prepared at all before.",
                author: "Kinsley A., Sophomore, 2024 former student"
              },
              {
                quote: "This was very helpful. I feel more prepared for the SAT, and it's reduced some of the stress that comes with the test.", //They have also helped me with different strategies that I can use for the test.
                author: "Kaleb B., Junior, 2024 former student"
              }
            ].map((testimonial, index) => (
              <Box key={index} sx={{display: {xs: index < 2 ? 'block' : 'none', md: 'block'}}}>
                <Paper sx={{ 
                  p: 3, height: '100%', display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  backdropFilter: 'blur(10px)', color: 'white'
                }}>
                  <Typography variant="body1" color={theme.palette.text.primary} sx={{ mb: 2, fontStyle: 'italic', fontSize: '1.4rem', lineHeight: 1.6 }}>"{testimonial.quote}"</Typography>
                  <Typography variant="subtitle2" color={theme.palette.text.primary}>  - {testimonial.author}</Typography>
                </Paper>
              </Box>
              ))}
            </Box>
        </Container>
      </Box>


      {/* Benefits Section - in the future, write articles and link to them from the bullet points here */}
      <Box sx={{ backgroundColor: theme.palette.background.default, pt: 6, pb: 10 }}>
        <Container>
          {/*<Typography variant="h3" component="h2" align="center" sx={{ mb: 4, color: theme.palette.text.primary }}>
            Earn Scholarships and Get into Dream Schools
          </Typography>*/}
          
          <Box sx={{display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center',
            gap: 4}}>
            {/* Left side content */}
            <Box sx={{ flex: 1, order: { xs: 2, md: 1 } }}>
              <Paper elevation={3}
                sx={{p: 3, height: '100%', display: 'flex', flexDirection: 'column',
                  borderLeft: `5px solid ${theme.palette.primary.main}`, borderRadius: 2}}
              >
                <Typography variant="h5" sx={{ mb: 2, fontWeight: 'medium' }}>
                  {/*We understand what matters most to you*/}
                  SAT scores give students an advantage in admissions and scholarships. 
                </Typography>
                <Typography variant="body1" sx={{ mb: 1, fontSize: '1.1rem', lineHeight: 1.6 }}>
                  High quality SAT Prep is an investment in future career opportunities, college admissions, and scholarship potential.                         
                </Typography>
                
                <List>
                  {[
                    'Create a profile to see how your estimated score compares to top schools.',
                    'Higher scores can lead to $10,000+ in merit scholarships',
                    'Most colleges still require the SAT, including Harvard, Yale, University of Georgia, Florida State, and many more.'
                    //'The confidence gained extends beyond test day into college applications',
                    //'Our proven methods have helped students get into Ivy League and top-tier schools'
                  ].map((item, index) => (
                    <ListItem key={index} sx={{ py: 1 }}>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
                
                <Button variant="contained" color="primary" sx={{ mt: 2, height: '60px', width: 'fit-content', 
                  alignSelf: 'flex-start', fontSize: '1.2rem', fontWeight: 'bold' }}
                  onClick={handleBenefitSectionCTAButtonClick}
                >
                  Start Free Trial Now!
                </Button>
              </Paper>
            </Box>
            
            {/* Right side image */}
            <Box sx={{flex: 1, order: { xs: 1, md: 2 }, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Box component="img" 
                src="/images/thrilled-high-def.png"
                alt="Student celebrating college acceptance and SAT test prep success"
                sx={{maxWidth: '100%', maxHeight: '452.85px', height: 'auto', borderRadius: 2, boxShadow: 3,
                  border: `1px solid ${theme.palette.divider}`}}
              />
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Our Story Section 
      Inspiration from Acely: Meet Our Founder
Vivian Shen, Acely Founder
“When I applied to Stanford University at 17, I couldn’t have imagined what the next decade of my life would hold. Like many other high school seniors, I was making BIG decisions that would impact everything about my future. I had always dreamed of being an engineer at Google, but that quickly evolved. First, as a consultant at McKinsey & Company, then to running product at Operator, and finally, founding my own company (YC’18). “
Vivian founded Acely to empower students to discover their best futures. Vivian and the Acely team truly believe when students have access to the right kind of support, tools, and peer community, it can change lives.*/}
      <Box sx={{ backgroundColor: theme.palette.primary.main, color: 'white', py: 10 }}>
        
        
        <Container>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
            {/* 6+ Years of Teaching Experience */}
            <Box sx={{display: 'flex', flexDirection: { xs: 'column', md: 'row-reverse' }, 
              alignItems: 'center', gap: 4}}>
              {/* Left side image (appears on right on desktop) */}
              <Box sx={{ order: { xs: 2, sm: 1}, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box 
                  component="img" 
                  src="/images/homepage/class-at-wren.jpg"
                  alt="Ivan teaching an SAT prep class at Wren High School"
                  sx={{
                    maxHeight: {xs: 'auto', lg: '375px'},
                    width: 'auto',
                    height: 'auto',
                    borderRadius: 2,
                    boxShadow: 3,
                    border: `1px solid ${theme.palette.divider}`
                  }}
                />
              </Box>
              
              {/* Right side content (appears on left on desktop) */}
              <Box sx={{ order: { xs: 1, sm: 2}, width: '100%' }}>
                <Paper elevation={3} sx={{p: 3, height: '100%', display: 'flex', flexDirection: 'column'}}
                >
                  <Typography variant="h5" sx={{ mb: 2, fontWeight: 'medium', color: theme.palette.primary.main }}>
                      6+ Years of Proven Results
                    {/*Meet Our Founder: Ivan Harjehausen*/}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 3, fontSize: '1.1rem', lineHeight: 1.6 }}>
                    Next Step Education brings over 6 years of specialized SAT tutoring experience to students nationwide.
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 3, fontSize: '1.1rem', lineHeight: 1.6 }}>
                    From teaching SAT prep and college guidance classes in high schools to providing one-on-one online tutoring, we've helped hundreds of students improve their scores by 100-250 points and gain admission to their dream schools.
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-evenly' }}>
                    {/*<Box sx={{ textAlign: 'center' }}>
                      <Typography variant="h4" color="primary" fontWeight="bold">6+</Typography>
                      <Typography variant="body2">Years Experience</Typography>
                    </Box>*/}
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant="h4" color="primary" fontWeight="bold">100-250</Typography>
                      <Typography variant="body2">Point Increases</Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant="h4" color="primary" fontWeight="bold">1,000+</Typography>
                      <Typography variant="body2">Students Helped</Typography>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>

            {/* Research and Book Influences */}
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', gap: 4}}>
              {/* Left side content */}
              <Box sx={{ flex: '3', width: '100%' }}>
                <Paper elevation={3} sx={{p: 3, height: '100%', display: 'flex', flexDirection: 'column'}}
                >
                  <Typography variant="h5" sx={{ mb: 2, fontWeight: 'medium', color: theme.palette.primary.main }}>
                    Research-Backed Methodology
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 3, fontSize: '1.1rem', lineHeight: 1.6 }}>
                    Our SAT program isn't just another test prep course. It's built on extensive research in cognitive science, educational psychology, and proven learning techniques.
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: '1.1rem', lineHeight: 1.6 }}>
                    We've analyzed thousands of SAT questions, studied the most effective learning methods, and incorporated insights from leading educational researchers to create a program that delivers results efficiently.
                  </Typography>
                </Paper>
              </Box>
              
              {/* Right side image */}
              <Box sx={{ flex: '2', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box component="img" 
                  src="/images/homepage/book-influences.png"
                  alt="Research books and influences behind our SAT methodology"
                  sx={{maxWidth: '100%', maxHeight: '300px', borderRadius: 2, boxShadow: 3,
                    border: `1px solid ${theme.palette.divider}`}}
                />
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>


      {/* How It Works Section */}
      <Box sx={{ pb: 4, backgroundColor: 'white' }}>
        <Box display="flex" sx={{width: '100%', py: 6, maxWidth: '100%', justifyContent: 'center', alignItems: 'center',
            height: 'auto', backgroundColor: theme.palette.background.default}} >
          <Typography variant="h3" component="h2" align="center">
            How Students Quickly Improve 100+ Points
            {/*Alternative copy:
            4 Steps to Improve SAT Scores 100+ Points
            How Students Improve 100+ Points in 1 Month*/}
          </Typography>
        </Box>
        <Container sx={{maxWidth: '1300px !important'}}>
          
          
          
          {[
            {
              text: "Focus on the most frequent questions first, working up from Easy to Hard.",
              imagePosition: "left",
              image: '/images/homepage/skill-summary.png'
            },
            {
              text: "Learn proven strategies and practice all skills and question types, improving the % correct.",
              imagePosition: "right",
              image: '/images/homepage/subject-skills-difficulties.png'
            },
            {
              text: "In 5 question sets, get hints, explanations, and personalized guidance from your personalized AI tutor.",
              imagePosition: "right",
              image: '/images/homepage/ai-chat.png'
            },
            {
              text: "See your student get excited as their estimated scores improves, and compare scores to peers, target score, and dream colleges.",
              imagePosition: "left",
              image: '/images/homepage/track-progress.png'
            }
          ].map((item, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 3, gap: 3,
              flexDirection: { xs: 'column', 
                //md: item.imagePosition === "left" ? 'row-reverse' : 'row'
                md: 'row' }}}
            >
              {/* Text Content */}
              <Box sx={{ flex: 1, mt: {xs: 2, md: 0} }}>
                <Paper 
                  elevation={3}
                  sx={{ 
                    p: 2, 
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    borderLeft: `5px solid ${theme.palette.primary.main}`,
                    borderRadius: 2
                  }}
                >
                  <Typography variant="h5" sx={{fontWeight: 'medium', textAlign: 'center' }}>
                    Step {index + 1}.
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: '1.1rem', lineHeight: 1.6 }}>
                    {item.text}
                  </Typography>
                </Paper>
              </Box>
              
              {/* Screenshots of the app (should be videos later on) */}
              <Box sx={{ flex: 2, p: {xs: 0, md: 0}, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Box
                component="img"
                src={item.image}
                alt={`Feature ${index + 1}`}
                sx={{
                  maxWidth: '100%',
                  maxHeight: 400,
                  objectFit: 'contain',
                  borderRadius: 2,
                  padding: 1,
                  border: `2px solid ${theme.palette.custom.lightGrey}`
                }}
              />
              </Box>
            </Box>
          ))}
        </Container>
      </Box>


      {/* Services Section */}
      <Container sx={{ pt: {xs: 4, sm: 8 }, pb: 8 }}>
        <Typography variant="h3" component="h2" align="center" sx={{ mb: 6 }}>
          Choose Your Study Path
        </Typography>
        <Box sx={{display: 'flex', flexDirection: {xs: 'column', sm:'row'}, alignItems: {xs:'center', sm:'flex-start'}, justifyContent: 'center',
        gap: {xs: 2, md: 3}, width: '100%',  px: {xs: 2, sm: 3, md: 4}}}>
          {[
            {
              title: "SAT Prep Premium",//Premium Learning
              price: "$29.99/month",
              paragraphText: "",
              features: [
                "1,200+ high quality SAT practice questions",
                "Personalized AI tutor giving strategies, explanations, and personalized feedback",
                "Automated reporting, analytics dashboard, and estimated score",
                //"Voice interaction with AI tutor (Speech-to-Text)",
                //"Access to all future features and updates"
              ],
              buttonText: "Get SAT Prep Premium Now!",
              buttonVariant: 'contained' as const,
              highlighted: true //outlines in orange as the "suggested" option
            },
            {
              title: "1-Month Trial (Sign Up By 3/31)",
              price: "Free",
              paragraphText: "We believe everyone deserves access to the best SAT prep. That's why we're offering a 1-month free trial to all new students. No credit card required. Cancel anytime.",
              features: [
                "Get access to all features and practice questions.",
                "See if it's the right fit for you before committing to a subscription."
                //"If you like it, please subscribe and share feedback so we can help others!"
                /*"1,200+ high quality SAT practice questions",
                "Strategies, explanations, and feedback from AI tutor",
                "Personalized analytics dashboard and estimated score",
                /"Limited daily practice sessions",*/
              ],
              buttonText: "Start Free Trial Now!",
              buttonVariant: 'contained' as const,
              highlighted: false //outlines in orange as the "suggested" option
            },
            {
              title: "SAT Prep Premium - 1-Year Access",
              price: "$99",
              features: [
                "Everything in SAT Prep Premium plus...",
                "1-YEAR access, SAVING $141 versus monthly plan",
                "Priority access to all future features and updates",
                "Chance to win 1 free SAT tutoring session with founder Ivan Harjehausen"
                //"LIFETIME access with no monthly payments",
                //"Premium voice interaction with AI tutor",
              ],
              buttonText: "Get SAT Prep Premium - 1-Year Access Now!",
              buttonVariant: 'contained' as const,
              highlighted: false //outlines in orange as the "suggested" option
            }
          ].map((tier, index) => (
            <Box key={index} sx={{width: '100%', maxWidth: {xs: '100%', md: '600px', mx: 'auto'}, flex: 1,
              height: 'auto', minHeight: '400px'}} >
              <Paper sx={{p: 4, height: '100%', display: 'flex', flexDirection: 'column',
                ...(tier.highlighted && {
                  border: `2px solid ${theme.palette.primary.main}`,
                  boxShadow: `0 4px 20px ${theme.palette.primary.main}40`
                })
              }}>
                <Typography variant="h4" component="h3" align="center" sx={{ mb: 2 }}>
                  {tier.title}
                </Typography>
                <Typography variant="h3" component="p" align="center">
                  {tier.price}
                </Typography>
                {tier.paragraphText && (
                  <Typography variant="body1" sx={{ mt: 2, mb: 0 }}>
                    {tier.paragraphText}
                  </Typography>
                )}
                <List sx={{ flexGrow: 1 }}>
                  {tier.features.map((feature, featureIndex) => (
                    <ListItem key={featureIndex} sx={{ py: 1 }}>
                      <ListItemIcon>
                        <CheckIcon color={tier.highlighted ? "primary" : "success"} />
                      </ListItemIcon>
                      <ListItemText primary={feature} />
                    </ListItem>
                  ))}
                </List>
                <Button fullWidth variant={tier.buttonVariant} color={tier.highlighted ? "primary" : "secondary"}
                  onClick={() => handleCheckoutClick(tier)}
                  sx={{ mt: 2, height: '60px', width: 'fit-content', 
                    alignSelf: 'center', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  {tier.buttonText}
                </Button>
              </Paper>
            </Box>
          ))}
        </Box>
      </Container>


      {/* FAQ Section with dropdowns*/}
      <FAQSection onCTAClick={handleFAQSectionCTAButtonClick} ctaButtonText="Start Free Trial Now!" 
        />
      

    </Box>
  );
};

export default HomePage;


{/* Main Content Section - obsolete now probably
      <Container 
        disableGutters
        sx={{ py: 8 }}
      >
        <Typography variant="h3" component="h2" align="center" sx={{ mb: 2 }}>
          Guiding Your Path to SAT Excellence
        </Typography>

        {isMobile ? (
          // Mobile Layout
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {/* Image first on mobile 
            <Box>
              <Box 
                component="img" 
                src="/images/thrilled-student.JPG" 
                alt="Student celebrating SAT success"
                sx={{ width: '100%', height: 'auto', borderRadius: 2, boxShadow: 3 }}
              />
            </Box>
            
            {/* Content section 
            <Paper elevation={4} sx={{ p: 2 }}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                We're here to make SAT prep easy and effective to get into your dream schools 
                with scholarships!
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                We break down the test by each question type so you can drill what is most 
                important to you and get instant feedback and strategies to improve your SAT score!
              </Typography>
              <List>
                {[
                  'Review essential math knowledge and strategies to ace the math section',
                  'Learn the most common grammar rules to reduce mistakes',
                  'Increase your reading speed and ability to understand complex passages',
                  'Develop learning habits that will help you in college and beyond!'
                ].map((text, index) => (
                  <ListItem key={index} sx={{ py: 1 }}>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Box>
        ) : (

          <Box display="flex" flexDirection="row" gap={3} alignItems="center">
            <Box sx={{ flex: 5}}>
              {/* Content section 
              <Paper elevation={4} sx={{ p: { xs: 2 } }}>
                <Typography sx={{ fontSize: '1.4rem', lineHeight: 1.6 }} variant="body1">
                  Get into your dream schools with scholarships with easy and effective SAT prep!
                  <br/>
                  We break down the test by each question type so you get instant feedback and strategies to improve your SAT score!
                </Typography>
                <List>
                  {[
                    'Review essential math knowledge and strategies to ace the math section',
                    'Learn the most common grammar rules to reduce mistakes',
                    'Develop learning habits that will help you in college and beyond!'
                  ].map((text, index) => (
                    <ListItem key={index} sx={{ py: 1 }}>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText 
                        primary={
                          <Typography sx={{ fontSize: '1.25em !important', lineHeight: 1.6 }}>
                            {text}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Box>
            <Box sx={{ flex: 4 }}>
              {/* Image section removed: borderRadius: 2, boxShadow: 3 
              <Box component="img" src="/images/thrilled-student.JPG" alt="Student celebrating SAT success"
                sx={{ width: '100%', height: 'auto', borderRadius: 2, boxShadow: 3}}
              />
            </Box>
          </Box>
        )}
      </Container> */}