"use client"

import React, { useState, useEffect, useMemo, useContext } from "react"
import { Box, Card, CardContent, CardHeader, Typography, Stack, Button, CircularProgress } from '@mui/material';
import Grid2 from '@mui/material/Grid2';

import { estimateSATScore, estimateMathScore, estimateReadingScore } from './sat-score-estimation';
import { SkillChartByDifficultyAndFrequency } from "./skills-by-difficulty-chart"
//import { SkillSummaryTreemap } from "./skills-by-frequency-chart" //Component is commented out; cool treemap but not needed
import { SkillProgressChecklist } from "./study-plan"
import { SkillSummaryPracticeTests } from "./practice-tests-chart"
import { ImprovementAreasContainer, ImprovementAreasVisualization } from "./improvement-areas-visualization"
import { UserContext, categories, UserData } from "../UserContext"
import { EditProfile } from "./EditProfile"
import  ScoreBar from "./sat-bars-score-estimation";
import DetailedAssessmentWithUniversities from "./sat-bars-score-estimation";


/* ********************************** Future Development Ideas **********************************a
ESSENTIAL USABILITY FEATURE: Password Reset in Login.tsx!!!
???Make SAT score estimation a range just like Math and Reading & Writing are? Or remove all ranges?
1. Add practice tests with Score estimations & extra score progress bar chart 
2. Add diagnostic tests for new students that informs a study-plan!
--> If I do those tests, consider using the toggle slider on the PersonalizedBar (or if not, delete that)
3. Create a study-plan with an overall progress tracking, weekly checklist, and self-reflection
4. Add a line graph of estimated SAT score based on user's performance on each skill, showing improvement over time
5. Add a puzzle/beautiful picture which is slowly unlocked with more correct answers of each skill

New practice tests data
const practiceTests = [
  {
    testName: "Test 1",
    date: "Dec 1",
    math: 580,
    "reading-and-writing": 560,
    overall: 1140
  },
  {
    testName: "Test 2",
    date: "Dec 15",
    math: 620,
    "reading-and-writing": 600,
    overall: 1220
  },
  {
    testName: "Test 3",
    date: "Dec 29",
    math: 680,
    "reading-and-writing": 650,
    overall: 1330
  }
]*/

export function ExpandedDashboard() {
  const { user, loading, error } = useContext(UserContext);
  const [activeChallenges, setActiveChallenges] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const [isEditing, setIsEditing] = useState(false);

  //if memoizing messes this up, repalce the "mathScores" with "estimateMathScore(user?.skills)", etc.
  const mathScores = useMemo(() => estimateMathScore(user?.skills), [user?.skills]);
  const readingScores = useMemo(() => estimateReadingScore(user?.skills), [user?.skills]);
  const totalScore = useMemo(() => estimateSATScore(user?.skills), [user?.skills]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">Error loading scores: {error.message}</Typography>;
  }

  //******Add "activeChallenges: [string] to the user object 
  // Make these redirect directly to doing the SAT questions
  const addChallenge = (challenge: string) => {
    setActiveChallenges([...activeChallenges, challenge])
  }

  return (
    <Box sx={{ width: '100%', margin: '10vh 0 auto', padding: '0 2vw' }}>
      <Stack spacing={1}>
        
        <DetailedAssessmentWithUniversities />
        <Card sx={{ bgcolor: 'background.paper' }}>
          <CardHeader sx={{ py: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative',
            '& .MuiCardHeader-content': { width: '100%'},
            '& .MuiCardHeader-action': { width: '100%', margin: 0, mt: 2, display: 'flex', justifyContent: 'space-evenly'}
            }
          }/>
          
              {/****************Need to implement AI call to summarize insights from overall SAT data + recent data (5 or 10 questions) + 
               * all missedQuestions or missedQuestionsNotMastered (need to build that structure too)
               
              <Grid2 item sx={{ gridColumn: { xs: "span 12", md: "span 6" } }}>
                <Card>
                  <CardHeader sx={{ py:1 }} title={<Typography variant="h6" color="primary.main">Personal Growth</Typography>} />
                  <CardContent sx={{ py:0}}>
                    <Typography variant="subtitle1" color="primary" gutterBottom>Celebrating Recent Successes</Typography>
                    <Box component="ul" sx={{ pl: 2, mb: 1 }}>
                      <Typography component="li" variant="body2" color="text.secondary">
                        • Improved Algebra score by 15% in the last practice test
                      </Typography>
                      <Typography component="li" variant="body2" color="text.secondary">
                        • Consistently scored above 80% in Word Choice questions this week
                      </Typography>
                    </Box>

                    <Typography variant="subtitle1" color="primary" gutterBottom>Recent Mistakes & Learnings</Typography>
                    <Box component="ul" sx={{ pl: 2 }}>
                      {/* TODO: Implement AI-generated content for mistakes and learnings }
                      <Typography component="li" variant="body2" color="text.secondary">
                        • Confused "affect" and "effect" in the last practice test. Remember: "affect" is usually a verb, "effect" is usually a noun.
                      </Typography>
                      <Typography component="li" variant="body2" color="text.secondary">
                        • Made calculation errors in algebra problems. Takeaway: Always double-check my work, especially with negative numbers.
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid2>

              {/* TODO: Implement Full or Half-Length Practice and Diagnostic Tests }
              <Grid2 item sx={{ gridColumn: { xs: "span 12", md: "span 6" } }}>
                <Card>
                  <CardHeader sx={{ py:1}} title={<Typography variant="h6" color="primary.main">Practice Test Progress</Typography>} />
                  <CardContent sx={{ py:0}}>
                    <Box sx={{ height: 250 }}>
                      <SkillSummaryPracticeTests practiceTests={practiceTests} />
                    </Box>
                  </CardContent>
                </Card>
              </Grid2>*/}

          <CardContent sx={{ py: 1 }}>
            <ImprovementAreasContainer onAddChallenge={addChallenge} />
          </CardContent>
        </Card>

        <Card sx={{ bgcolor: 'background.paper' }}>
          <CardHeader sx={{ pt: 1, pb: 0 }}/>
          <CardContent sx={{ py: 1 }}>
            <SkillChartByDifficultyAndFrequency/>
            {/*<Box sx={{ mb: 2, display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 3 }}>
              {['Skills by Difficulty', 'Skills by Frequency', 'Study Plan'].map((tab, index) => (
                <Button
                  key={tab}
                  onClick={() => setActiveTab(index)}
                  variant={activeTab === index ? 'contained' : 'outlined'}
                  sx={{
                    width: '100%',
                    bgcolor: activeTab === index ? 'background.paper' : 'grey.200',
                    color: 'text.primary',
                    border: theme => `1px solid ${activeTab === index ? theme.palette.grey[300] : 'transparent'}`,
                    '&:hover': {
                      bgcolor: 'background.paper',
                      border: theme => `1px solid ${theme.palette.grey[300]}`,
                    },
                    boxShadow: activeTab === index ? 1 : 'none',
                  }}
                >
                  {tab}
                </Button>
              ))}
            </Box>
            <Box mt={2}>
              {activeTab === 0 && (
                <SkillChartByDifficultyAndFrequency/>
              )}
              {activeTab === 1 && (
                <SkillSummaryTreemap/> 
              )}
              {activeTab === 2 && (
                <SkillProgressChecklist/>
              )}
            </Box>   {/*Removed from SkillSummaryTreemap and Heatmap:  skills={skills} skillPerformance={skillPerformance} */}
          </CardContent>
        </Card>
        <EditProfile open={isEditing} onClose={() => setIsEditing(false)} />
      </Stack>
    </Box>
  )
}

